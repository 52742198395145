import React, { Fragment } from 'react';
import { Navbar, NavbarBrand,Button } from 'reactstrap';
import { useStoreActions } from "../store";
import { useStoreState } from 'easy-peasy';

function MyNavbar(props){
    const previousState = useStoreActions(actions => actions.previousState);
    const myhistory = useStoreState(state => state.myhistory);

    return (
        <Fragment>
            
            <Navbar  light expand="md" className="non-printable myNavbar">
            
                <NavbarBrand href="/">
                    <img src="/logo.jpg" style={{background: '#fff!important'}} alt="logo EBRD"></img>
                </NavbarBrand>
                {
            props.loggedin && myhistory.length > 0 && 
                <Button color="link" className="float-right non-printable ml-auto" style={{margin:'10px 30px', background: '#fff!important'}}  onClick={() => {previousState()}}><img src="back.png" style={{width: '40px'}} alt="Back" /></Button>

            }
                
            </Navbar>
            
        </Fragment>
        )
}

export default MyNavbar;