import * as $ from 'jquery';
import Questionnaire from '../Models/Questionnaire';
import { AssessmentAnswer } from '../Models/AssessmentAnswer';
import axios from 'axios';
import { Assessment } from '../Models/Assessment';
import { User } from '../Models/User';

const url = 'https://esg.think-plus.gr/';
const filesUrl = 'https://esgfiles.thinkplus.dev/';


// class ResetPasswordResponse{
//     success ?: boolean;
//     message ?: string;
// }

export class QuestionnaireService{

    fetchAssessments(): Promise<Assessment[]>{
        const fetchAssessmentsPromise = new Promise<Assessment[]> ((resolve, reject) => {
            $.ajax({
                method: 'get',
                url: url + 'questionnaire/assessments',
                success: (response) => {
                    response.sort((a:Assessment, b:Assessment) => { if(a.asset && b.asset && a.asset.assetTitle && b.asset.assetTitle) return a.asset.assetTitle.trim() >= b.asset.assetTitle.trim() ? 1 : -1; else return 1});
                    resolve(response);
                }
            })
        });
        return fetchAssessmentsPromise;
    }

    fetchQuestionnaires(): Promise<Questionnaire[]>{
        const questionnairesPromise = new Promise<Questionnaire[]> ((resolve) => {
            $.ajax({
                method: 'get',
                url: url + 'questionnaire/all',
                success: function(result){
                    for(let i = 0; i < result.length; i ++) {
                        for(let j = 0; j < result[i].questionSections.length; j ++) {
                            for(let k = 0; k < result[i].questionSections[j].questionGroups.length; k ++) {
                                for(let l = 0; l < result[i].questionSections[j].questionGroups[k].questions.length; l++) {
                                    let question = result[i].questionSections[j].questionGroups[k].questions[l];
                                    if(question.qRange){
                                        question.qRangeUi = [];
                                        let rr = question.qRange.split(',');
                                        question.qRangeUi.push(parseInt(rr[0], 10));
                                        question.qRangeUi.push(parseInt(rr[1], 10));
                                        if(question.qRangeUi[1] === 0){
                                            question.qRangeUi[0] = -10
                                        }
                                    }
                                    if(question.qType === 'yesno'){
                                        question.qYesImpact = question.qYesImpact ? question.qYesImpact : 1;
                                        question.qNoImpact = question.qNoImpact ? question.qNoImpact : 0;
                                    }
                                    if(question.answers){
                                        for(let m = 0; m < question.answers.length; m++){
                                            question.answers[m].aaImpact = question.answers[m].aaImpact ? question.answers[m].aaImpact : 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    resolve(result as Questionnaire[]);
                }
            })
        });
        return questionnairesPromise;
        
    }

    answerQuestion(aa: AssessmentAnswer, qairId: number){
        const answerQuestionPromise = new Promise<boolean|string>((resolve) => {
            console.log(aa.aaFilesUpload);
            const fd = new FormData();
            console.log(aa.aaFilesUpload);
            if(aa.aaFilesUpload && aa.aaFilesUpload.length){
                for(let i = 0; i < aa.aaFilesUpload.length; i ++){
                    fd.append('files', aa.aaFilesUpload[i]);
                }
                $.ajax({
                    method: 'post',
                    data: fd,
                    processData: false,
                    contentType: false,
                    url: filesUrl,
                    dataType: 'json',
                    success: function(response){
                        aa.aaFiles = response;
                        console.log(JSON.stringify(response));
                        delete aa.aaFilesUpload;
                        // tslint:disable-next-line: no-console
                        axios.post(url + 'questionnaire/answerQuestion', { ...aa, qairId }).then(
                            function(res){
                                if(res.data.success){
                                    resolve(JSON.stringify(response));
                                }
                                else{
                                    resolve(false);
                                }
                            });
                        },
                        error: function(e){
                            console.log(e);
                            resolve(false);
                        }
                });
            }
            else{
                axios.post(url + 'questionnaire/answerQuestion', { ...aa, qairId }).then(
                    function(res){
                        if(res.data.success){
                            resolve(true);
                        }
                        else{
                            resolve(false);
                        }
                    });
            }
        });
        return answerQuestionPromise;
    }

    login(email: string, password: string): Promise<User>{
        const loginPromise = new Promise<User>((resolve, reject) => {
            axios.post(url + 'auth/login', {email, password}).then((response) => {
                if(response.data.success){
                    const u = new User();
                    u.userEmail = response.data.user.userEmail;
                    u.userGroup = response.data.user.userGroup;
                    u.userName = response.data.user.userName;
                    u.userSurname = response.data.user.userSurname;
                    u.userId = response.data.userId;
                    localStorage.setItem('userData', JSON.stringify(u));
                    resolve(u);
                }
                else{
                    reject('Wrong Email or Password');
                }
            });
        });
        return loginPromise;
    }


    resetPassword(email: string){
        const resetPasswordPromise = new Promise((resolve, reject) => {
            axios.post(url + 'auth/reset-password', {email}).then((response) => {
                if(response.data.success){
                    resolve(true);
                }
                else{
                    reject(response.data.message);
                }
            }).catch(e => { 
                reject(e.message);
            });
        });
        return resetPasswordPromise;
    }


}