import React, { useEffect} from 'react';
import { Container, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { useStoreActions } from "../store";

function GlossaryComponent(){
    const hideGlossary = useStoreActions(actions => actions.hideGlossary);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container >
            <Card>
                <CardHeader>
                    <h4 className="text-center">Glossary</h4>
                </CardHeader>
                <CardBody>
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <th style={{ width: 30 }}>
                                    CDP
                                </th>
                                <td style={{ width: 70 }}>
                                Carbon Disclosure Project
                                </td>
                            </tr>
                            <tr>
                                <th>
                                CO
                                </th>
                                <td>
                                Carbon Oxide
                                </td>
                            </tr>
                            <tr>
                                <th>
                                dB
                                </th>
                                <td>
                                Decibel
                                </td>
                            </tr>
                            <tr>
                                <th>
                                D&amp;I
                                </th>
                                <td>
                                Diversity &amp; Inclusion
                                </td>
                            </tr>
                            <tr>
                                <th>
                                DNK
                                </th>
                                <td>
                                Deutscher Nachhaltigkeitskodex
                                </td>
                            </tr>
                            <tr>
                                <th>
                                E&amp;S
                                </th>
                                <td>
                                Environment &amp; Social
                                </td>
                            </tr>
                            <tr>
                                <th>
                                EBRD
                                </th>
                                <td>
                                European Bank for Reconstruction and Development
                                </td>
                            </tr>
                            <tr>
                                <th>
                                EOL
                                </th>
                                <td>
                                End of Life
                                </td>
                            </tr>
                            <tr>
                                <th>
                                EPBD
                                </th>
                                <td>
                                Energy Performance of Buildings Directive
                                </td>
                            </tr>
                            <tr>
                                <th>
                                ESG
                                </th>
                                <td>
                                Environmental, Social and Governance
                                </td>
                            </tr>
                            <tr>
                                <th>
                                GHG
                                </th>
                                <td>
                                Greenhouse Gas Emissions
                                </td>
                            </tr>
                            <tr>
                                <th>
                                GRI
                                </th>
                                <td>
                                Global Reporting Initiative
                                </td>
                            </tr>
                            <tr>
                                <th>
                                HRADF
                                </th>
                                <td>
                                Hellenic Republic Asset Development Fund
                                </td>
                            </tr>
                            <tr>
                                <th>
                                ICAO
                                </th>
                                <td>
                                International Civil Aviation Organisation
                                </td>
                            </tr>
                            <tr>
                                <th>
                                ILO
                                </th>
                                <td>
                                International Labour Organisation
                                </td>
                            </tr>
                            <tr>
                                <th>
                                ISO
                                </th>
                                <td>
                                International Standardisation Organisation
                                </td>
                            </tr>
                            <tr>
                                <th>
                                IUCN
                                </th>
                                <td>
                                International Union for Conservation of Nature
                                </td>
                            </tr>
                            <tr>
                                <th>
                                IT
                                </th>
                                <td>
                                Information Technology
                                </td>
                            </tr>
                            <tr>
                                <th>
                                KPI
                                </th>
                                <td>
                                Key Performance Indicator
                                </td>
                            </tr>
                            <tr>
                                <th>
                                KWh
                                </th>
                                <td>
                                Kilo Watt hour
                                </td>
                            </tr>
                            <tr>
                                <th>
                                OECD
                                </th>
                                <td>
                                Organisation for Economic Co-operation and Development
                                </td>
                            </tr>
                            <tr>
                                <th>
                                OEF
                                </th>
                                <td>
                                Organisation Environmental Footprint
                                </td>
                            </tr>
                            <tr>
                                <th>
                                PRI
                                </th>
                                <td>
                                Principles for Responsible Investing
                                </td>
                            </tr>
                            <tr>
                                <th>
                                SASB
                                </th>
                                <td>
                                Sustainability Accounting Standards Board
                                </td>
                            </tr>
                            <tr>
                                <th>
                                SDGs
                                </th>
                                <td>
                                Sustainable Development Goals
                                </td>
                            </tr>
                            <tr>
                                <th>
                                TCFD
                                </th>
                                <td>
                                Task Force for Climate-related Financial Disclosures
                                </td>
                            </tr>
                            <tr>
                                <th>
                                UN
                                </th>
                                <td>
                                United Nations
                                </td>
                            </tr>
                            <tr>
                                <th>
                                UNGC
                                </th>
                                <td>
                                United Nations Global Compact
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter>
                    <Button className="btn-custom-blue btn-block" color="light" onClick={() => {hideGlossary()}}>Proceed with Assessment</Button>
                </CardFooter>
            </Card>
            <br /><br />
        </Container>
    )
}

export default GlossaryComponent;