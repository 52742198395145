import { Action, action } from 'easy-peasy';
import Questionnaire from './Models/Questionnaire';
import { Assessment } from './Models/Assessment';
import { AssessmentAnswer } from './Models/AssessmentAnswer';
import { NavigationSection } from './Models/NavigationSection';
import { Asset } from './Models/Asset';
import { User } from './Models/User';

export class LiveAnswer{
    activeAnswer?: number;
    activeYesNo?: string;
    textValue?: string;
    rangeValue?: number;
    multipleValue?: number[];
    commentValue?: string;
    filesValue?: FileList;
    liveFiles?: boolean | string;
    qId?: number;
    saved?: boolean;
}

export class MyStatePath{
    currentSection?: NavigationSection;
    showingGlossary?: boolean;
    showingPortfolio?: boolean;
}

export interface GlobalStateModel {
    myhistory: MyStatePath[];
    questionnaires: Questionnaire[];
    qair: number;
    assets: Asset[];
    asset?: Asset;
    isLoggedIn: boolean;
    loggedinUser?: User;
    loginErrorMessage?: string;
    liveAnswers: LiveAnswer[];
    assessments?: Assessment[];
    questionnaire?: Questionnaire;
    assessment?: Assessment;
    currentSection: NavigationSection;
    answerQuestion: Action<GlobalStateModel, AssessmentAnswer>;
    selectQuestionnaire: Action<GlobalStateModel, number>;
    questionnairesFetched: Action<GlobalStateModel, Questionnaire[]>;
    changeSection: Action<GlobalStateModel, NavigationSection>;
    liveAnswer: Action<GlobalStateModel, LiveAnswer>;
    assetsFetched: Action<GlobalStateModel, Asset[]>;
    assessmentsFetched: Action<GlobalStateModel, Assessment[]>;
    selectAsset: Action<GlobalStateModel, Asset>;
    selectAssessment: Action<GlobalStateModel, Assessment>;
    setLoginErrorMessage: Action<GlobalStateModel, string>;
    setLoggedIn: Action<GlobalStateModel, boolean>;
    setLoggedInUser: Action<GlobalStateModel, User | null>;
    toggleShowGlossary: Action<GlobalStateModel>;
    togglePortfolio: Action<GlobalStateModel>;
    previousState: Action<GlobalStateModel>;
    hidePortfolio: Action<GlobalStateModel>;
    hideGlossary: Action<GlobalStateModel>;
    showPage: Action<GlobalStateModel, string>;
    showingGlossary: boolean;
    showingPortfolio: boolean;
    showingPage: string;
    printing?: boolean;
    startPrinting: Action<GlobalStateModel>;
    stopPrinting: Action<GlobalStateModel>;
}

const globalState: GlobalStateModel = {
    isLoggedIn: localStorage.getItem('userData') ? true: false,
    myhistory: [],
    loggedinUser: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') as string) : undefined,
    currentSection: {
        qsIndex: 0,
        qgIndex: 0
    },
    showingPage: '',
    showingGlossary: true,
    showingPortfolio: false,
    assets: [],
    questionnaires: [],
    qair: -1,
    liveAnswers: [],
    questionnairesFetched: action((state, payload) => {
        state.questionnaires = payload;
    }),
    assessmentsFetched: action((state, payload) => {
        state.assessments = payload;
    }),
    selectQuestionnaire: action((state, payload) => {
        state.qair = payload;
        state.questionnaire = state.questionnaires[payload];
    }),
    answerQuestion: action((state, payload) => {
        if(state.assessment){
            if(state.assessment.answers){
                for(let i = 0; i < state.assessment.answers.length; i ++){
                    if(state.assessment.answers[i] && state.assessment.answers[i].qId === payload.qId){
                        delete state.assessment.answers[i];
                    }
                }
            }
            else{
                state.assessment.answers = [];
            }
            state.assessment.answers.push(payload);
        }
    }),
    changeSection: action((state, payload) => {
        state.myhistory.push({
            currentSection: state.currentSection,
            showingGlossary: state.showingGlossary,
            showingPortfolio: state.showingPortfolio
        });
        state.currentSection = payload;
        state.showingPortfolio = false;
        state.showingGlossary = false;
        state.showingPage = '';
        
    }),
    liveAnswer: action((state, payload) => {
        for(let i = 0; i < state.liveAnswers.length; i++ ){
            if(state.liveAnswers[i] && state.liveAnswers[i].qId === payload.qId){
                delete state.liveAnswers[i];
            }
        }
        state.liveAnswers.push(payload);
    }),
    assetsFetched: action((state, payload) => {
        state.assets = payload;
    }),
    selectAsset: action((state, payload) => {
        state.asset = payload;
    }),
    selectAssessment: action((state, payload) => {
        state.assessment = payload;
    }),
    toggleShowGlossary: action((state) => {
        state.myhistory.push({
            currentSection: state.currentSection,
            showingGlossary: state.showingGlossary,
            showingPortfolio: state.showingPortfolio
        });
        state.showingGlossary = true;
        state.showingPortfolio = false;
        state.showingPage = '';
        
    }),
    showPage: action((state, payload) => {
        state.myhistory.push({
            currentSection: state.currentSection,
            showingGlossary: state.showingGlossary,
            showingPortfolio: state.showingPortfolio
        });
        state.showingGlossary = false;
        state.showingPortfolio = false;
        state.showingPage = payload;
    }),
    togglePortfolio: action((state) => {
        state.myhistory.push({
            currentSection: state.currentSection,
            showingGlossary: state.showingGlossary,
            showingPortfolio: state.showingPortfolio
        });
        state.showingPortfolio = true;
        state.showingGlossary = false;
        state.showingPage = '';
        
    }),
    hidePortfolio: action((state) => {
        state.myhistory.push({
            currentSection: state.currentSection,
            showingGlossary: state.showingGlossary,
            showingPortfolio: state.showingPortfolio
        });
        state.showingPortfolio = false;
        
    }),
    hideGlossary: action((state) => {
        state.myhistory.push({
            currentSection: state.currentSection,
            showingGlossary: state.showingGlossary,
            showingPortfolio: state.showingPortfolio
        });
        state.showingGlossary = false;
        
    }),
    setLoginErrorMessage: action((state, payload) => {
        state.loginErrorMessage = payload;
    }),
    setLoggedIn: action((state, payload) => {
        state.isLoggedIn = payload;
    }),
    setLoggedInUser: action((state, payload) => {
        if(payload !== null){
            state.loggedinUser = payload;
        }
        else{
            delete state.loggedinUser;
        }
    }),
    previousState: action((state) => {
        if(state.myhistory.length > 0){
            const path = state.myhistory.pop();
            if(path && path.currentSection){
                state.currentSection = path.currentSection;
                state.showingGlossary = path.showingGlossary !== undefined ? path.showingGlossary : false;
                state.showingPortfolio = path.showingPortfolio !== undefined ? path.showingPortfolio : false;
            }
        }
    }),
    startPrinting: action((state) => {
        state.printing = true;
    }),
    stopPrinting: action((state) => {
        state.printing = false;
    }),
}

export default globalState;