import React, { Fragment, useEffect } from 'react';
import QuestionSection from '../Models/QuestionSection';
import QuestionGroup from '../Models/QuestionGroup';
import QuestionGroupComponent from './QuestionGroupComponent';
import { Button, Col, Row } from 'reactstrap';
import { NavigationSection } from '../Models/NavigationSection';
import { useStoreActions } from '../store';
import { useStoreState } from 'easy-peasy';

type QuestionSectionProps = {
    questionSection: QuestionSection,
    currentSection: NavigationSection
};

type QuestionSectionState = {
    currentGroup : number;
}

function QuestionSectionComponent(props: QuestionSectionProps){
    
    const changeSection = useStoreActions(actions => actions.changeSection);
    const assessment = useStoreState(state => state.assessment);
    function onGroupSubmit(){
        const newSection = {
            qsIndex: props.currentSection.qsIndex,
            qgIndex: props.currentSection.qgIndex ? props.currentSection.qgIndex + 1 : 1
        };
        changeSection(newSection);    
    }
    function onGroupBack(){
        const newSection = {
            qsIndex: props.currentSection.qsIndex,
            qgIndex: props.currentSection.qgIndex ? props.currentSection.qgIndex - 1 : 1
        };
        changeSection(newSection);    
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            {
                props.questionSection && props.questionSection.questionGroups &&
                props.questionSection.questionGroups.filter(qg => {
                    if(qg.questions && qg.questions[0] && (!qg.questions[0].qSectorSpecific || qg.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                        return true;
                    }
                    else{
                        return false;
                    }
                }).map((qg: QuestionGroup, index: number) => {
                    return (
                            index === props.currentSection.qgIndex &&
                            <Fragment key={index}>
                                <QuestionGroupComponent questionGroup={qg}></QuestionGroupComponent>
                                <br />
                                <Row>
                                    
                                <Col md="6">
                                {
                                    index > 0 && (
                                        <Button className="btn-block" color="info" onClick={onGroupBack}>Previous Set of Questions</Button>
                                    )
                                }
                                </Col>
                                <Col md="6">
                                {
                                    props.questionSection.questionGroups && props.currentSection.qsIndex !== undefined
                                    && index < props.questionSection.questionGroups.filter(qg => {
                                        if(qg.questions && qg.questions[0] && (!qg.questions[0].qSectorSpecific || qg.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                                            return true;
                                        }
                                        else{
                                            return false;
                                        }
                                    }).length-1 && (
                                        <Button className="btn-block" color="info" onClick={onGroupSubmit}>Next Set of Questions</Button>
                                    )
                                }
                                </Col>
                                </Row>
                                <hr />
                            </Fragment>
                    )
                })
            }
            {
                props.questionSection && props.questionSection.questionGroups &&
                props.currentSection.qgIndex === props.questionSection.questionGroups.length && (
                    <p>You have completed this section</p>
                )
            }
        </Fragment>
    );
}

export default QuestionSectionComponent;
