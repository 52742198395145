import React, { Fragment } from 'react';
import { useStoreActions } from "./store";
import { Container, Row, Col, Card, CardHeader, CardBody, Button} from 'reactstrap';
import './App.css';
import Questionnaire from './Models/Questionnaire';
import QuestionnaireComponent from './Components/QuestionnaireComponent';
import MyNavbar from './Components/MyNavbar';
import { QuestionnaireService } from './Services/QuestionnaireService';
import { useStoreState } from 'easy-peasy';
import LoginScreen from './Components/LoginScreen';

type AppProps = {
  questionnairId ?: number;
}

function App(props: AppProps){
  const questionnaireService: QuestionnaireService = new QuestionnaireService();
  const questionnaires = useStoreState(state=>state.questionnaires);
  const qair = useStoreState(state => state.qair);
  const currentSection = useStoreState(state => state.currentSection);
  const assessments = useStoreState(state => state.assessments);
  const assessment = useStoreState(state => state.assessment);
  const assignQs = useStoreActions(actions => actions.questionnairesFetched);
  const assignAssessments = useStoreActions(actions => actions.assessmentsFetched);
  const selectAssessment = useStoreActions(actions => actions.selectAssessment);
  const selectQair = useStoreActions(actions => actions.selectQuestionnaire);
  const isLoggedIn = useStoreState(state => state.isLoggedIn);
  const loggedInUser = useStoreState(state => state.loggedinUser);
  const setLoggedIn = useStoreActions(actions => actions.setLoggedIn);
  const setLoggedInUser = useStoreActions(actions => actions.setLoggedInUser);
  
  function logout(){
    localStorage.setItem('userData', '');
    setLoggedIn(false);
    setLoggedInUser(null);
  }
  if(questionnaires.length <= 0){
    questionnaireService.fetchQuestionnaires().then((result) => {
      assignQs(result);
    });
  }

  if(!assessments ){
    questionnaireService.fetchAssessments().then((result) => {
      assignAssessments(result);
    });
  }

  const onQuestionnaireSelect = (qairs: number) => {
    selectQair(qairs);
  }

  return (
    <Fragment>
      <div style={{width: '100%', padding: '30px', minHeight: '92px', background: '#65A8DD'}}>
        {
          <Fragment>
          <h5 className="float-right non-printable" ><Button color="link" style={{color: '#fff', marginTop: '-5px'}} href="/"><img src="./home.png" width="30px" /></Button></h5>
          {isLoggedIn && <Fragment>
            <h5 className="float-right non-printable" ><Button color="link" style={{color: '#fff'}} onClick={logout}>Logout</Button></h5>
            <h5 style={{color: '#fff'}} className="non-printable">
              {(loggedInUser.userName ? loggedInUser.userName : '') + ' ' 
              + (loggedInUser.userSurname ? loggedInUser.userSurname : '')}
            </h5>
            
            </Fragment>}
            </Fragment>
        }
        
      </div>
      <MyNavbar loggedin={isLoggedIn}></MyNavbar>
      <br /><br />
        {
          isLoggedIn && !questionnaires[qair] && (
            <Fragment>
              
              <Container>
                <h3 style={{fontSize: '24px'}} className="text-center">
                  Hellenic Republic Asset Development Fund (HRADF)<br />
                  Environmental, Social and Governance (ESG)<br /> Analysis and Rating Tool
                </h3>
                <p>Welcome to the HRADF ESG Analysis and Rating Tool.</p>
                <p>
                  Most frequently defined as ‘development that meets the needs of the 
                  present without compromising the ability of future generations to meet their own needs’, 
                  sustainable development has, in recent years, gained increasing prominence as a business 
                  and investment issue. Sustainable development covers a very broad, diverse agenda and – 
                  for the purposes, mission and mandate of the HRADF – can be broken down into Environmental, 
                  Social and Governance (ESG) issues.
                </p>
                <p>
                  Greece places particular emphasis on achieving sustainable development and is strongly 
                  committed to the implementation of the 2030 Agenda for Sustainable Development and its 17 
                  Sustainable Development Goals (SDGs). Also, the National Growth Strategy of Greece adopted 
                  in May 2018, is in line with the overall themes and provisions of the SDGs.
                </p>
                <p>
                  HRADF’s undergoing privatisation processes provide an opportunity to implement strategic 
                  decisions with long-term impacts. Except from the economic potential and fiscal advantages of 
                  privatisation, HRADF recognises that under the right conditions it can also yield environmental 
                  benefits and contribute to sustainable development.
                </p>
                <p>
                  The objective of this ESG Analysis and Rating Tool is the integration of ESG/Sustainability 
                  considerations, into the privatisation process to enhance transparency, help attract responsible 
                  investors, and eventually, lead to more sustainable outcomes that will raise the bottom-line 
                  performance for people, planet and economic prosperity.
                </p>
                <p>
                  The ESG Analysis and Rating Tool has been designed to create a user-friendly application to help a 
                  user evaluate every asset of HRADF portfolio. The tool is based on best international practices and 
                  frameworks and is in alignment with the SDGs and the environmental and social performance requirements 
                  of major funding and investment institutions. The tool brings together existing resources developed by 
                  international organisations to help the user screen environmental, social and governance issues of HRADF assets. 
                </p>
                <p>
                  The tool has been designed to be simple and effective to use – it automatically generates four (4) scores, 
                  one for the Environmental assessment (the E score), one for the Social assessment (S score), one for 
                  the Governance assessment (G score) and one, overall ESG Score. It also generates an ESG Analysis and Rating 
                  Report (in pdf format) and Recommendations for Improvement for further internal use by HRADF and other interested 
                  parties. The tool allows the user to rate each of the existing HRADF assets based on specified criteria, 
                  to perform benchmarking, and receive high-level recommendations for ESG enhancements of the asset.
                </p>
                <br />
                <Row>
                  {
                    questionnaires.map((qa: Questionnaire, ind: number) => {
                      return (
                      <Col md="12" xs="12" key={qa.qairId}>
                        <Card key={qa.qairId} >
                          <CardHeader style={{background: '#0D265E', color: '#ffffff'}}>
                            <h3 className="text-center">{qa.qairTitle}</h3>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md="7">
                                <select className="form-control" onChange={(e) => { console.log(e.target.value);selectAssessment(assessments[e.target.value]) }}>
                                  <option value="">Select Asset</option>
                                  {
                                    assessments && assessments.map((a, i) => {
                                      return (
                                      <option key={i} value={i}>{a.asset.assetTitle}</option>
                                      )
                                    })
                                  }  
                                </select> 
                              </Col>
                              <Col md="5">
                                <Button className="float-right btn-block btn-custom-blue" color="light" disabled={!assessment} onClick={() => {onQuestionnaireSelect(ind)}}>
                                  Take Assessment
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <br /><br />
                      </Col>)
                    })
                  }
                  
                </Row>
              </Container>
            </Fragment>
          )
        }
      {
        isLoggedIn && qair >= 0 && questionnaires[qair] && (
          <Fragment>
            
            <QuestionnaireComponent questionnaire={questionnaires[qair]} currentSection={currentSection}></QuestionnaireComponent>
          </Fragment>
        )
      }
      {
        !isLoggedIn && <LoginScreen></LoginScreen>
      }
      <div style={{width: '100%', height: '200px', background: 'url(/graphic.png)', backgroundSize: '100% 100%'}} className="non-printable"></div>
    </Fragment>
  )

}

export default App;
