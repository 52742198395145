import React, { Fragment, useEffect } from 'react';

function HelpDeskComponent(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <Fragment>
        <h4>HelpDesk</h4>
        <p>
            For any assistance with regard to the use of this ESG Analysis and Rating Tool please contact:
            <br /><br />
            <b>Maria Christantoni</b><br />
            Sustainability Officer<br />
        </p>
        <p>Address: 1 Kolokotroni &amp; Stadiou Str. | 105 62 | Athens | Greece</p>
        <p>Telephone:   [+30] 210 3274400</p>
        <p>Fax:            [+30] 210 3274449</p>
        <p>Direct:         [+30] 2103274412</p>
        <p>Mobile:        [+30] 6940826326</p>
        <p>Εmail:         mchristantoni[at]hraf.gr</p>
    </Fragment>
}

export default HelpDeskComponent;