import React, { Fragment, useEffect } from 'react';

function ResourcesComponent(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <h3 className="text-center">Resources</h3>
            <br />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>
                            RESOURCES AND LINKS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th><a href="https://airindex.eea.europa.eu/" target="_blank">Air Quality Index</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.cdp.net/en" target="_blank">CDP</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.deutscher-nachhaltigkeitskodex.de/" target="_blank">DNK</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ebrd.com/home" target="_blank">EBRD</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ebrd.com/who-we-are/our-values/environmental-and-social-policy/performance-requirements.html" target="_blank">EBRD Performance Requirements &amp; Guidance</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ebrd.com/who-we-are/our-values/environmental-emanual-toolkit.html" target="_blank">EBRD Environmental and Social Risk Management Manual (E-Manual)
                        EBRD E&amp;S Due Diligence (ESDD) Report for Financial Intermediaries (FIs)
                        EBRD Financial Intermediary Sustainability Index</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ebrd.com/cs/Satellite?c=Content&cid=1395258483298&pagename=EBRD%2FContent%2FDownloadDocument" target="_blank">EBRD Responsible Investment (RI) Index</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ebrd.com/who-we-are/our-values/environmental-emanual-risk.html" target="_blank">EBRD E&amp;S Risk Management Procedures</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://ec.europa.eu/energy/en/topics/energy-efficiency/energy-performance-of-buildings/energy-performance-buildings-directive" target="_blank">EPBT</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://s3.amazonaws.com/gresb-public/2019/Documents/2019_RE_Global_Results_Snapshot_Online.pdf" target="_blank">GRESB-The ESG Benchmark for Real Assets</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.globalreporting.org/standards/" target="_blank">GRI Standards</a></th>
                    </tr>
                    <tr>
                        <th><a href="http://www.ggk.gov.gr/wp-content/uploads/2018/06/VNR-Greece-2018.pdf" target="_blank">Hellenic Republic (The General Secretariat of Legal and Parliamentary issues)-Priorities and National KPIs for the SDGs</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.hradf.com/en/" target="_blank">HRADF</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.icao.int/safety/UA/UASToolkit/Pages/Toolkit-Guidelines.aspx" target="_blank">ICAO Guidelines</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ifc.org/wps/wcm/connect/Topics_Ext_Content/IFC_External_Corporate_Site/Sustainability-At-IFC/Policies-Standards/Performance-Standards" target="_blank">IFC-E&amp;S Performance Standards</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.ilo.org/global/lang--en/index.htm" target="_blank">ILO</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.iso.org/home.html" target="_blank">ISO</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.iucnredlist.org" target="_blank">IUCN Red List of Threated Species</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.msci.com/documents/1296102/1636401/MSCI-ESG-Rating-Brochure.pdf/f2b4a27a-58f5-42c7-880b-cf8201039eaa?t=1563171870192" target="_blank">MSCI-ESG Ratings Overview</a></th>
                    </tr>
                    <tr>
                        <th><a href="http://www.oecd.org/" target="_blank">OECD</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://ec.europa.eu/environment/eussd/smgp/pdf/OEFSR_guidance_v6.3.pdf" target="_blank">OEF Sector Rules Guidance</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.unpri.org/" target="_blank">PRI</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.robecosam.com/en/key-strengths/smart-esg-integration.html" target="_blank">RobecoSAM-The next Generation of ESG Scores</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.spratings.com/documents/20184/4918240/RatingsDirect_EnvironmentalSocialAndGovernanceEvaluationAnalyticalApproach_1176513_Apr-10-2019.pdf" target="_blank">S&amp;P Global-Environmental, Social and Governance Evaluation Analytical Approach</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.sasb.org/" target="_blank">SASB</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/" target="_blank">SDGs</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.sustainalytics.com/sustainable-finance/wp-content/uploads/2019/02/ESG-Risk-Rating-PDF-Sample-Company-Report.pdf" target="_blank">Sustainanalytics-ESG Risk Rating Report Sample</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.fsb-tcfd.org" target="_blank">TCFD</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.un.org/en/" target="_blank">UN</a></th>
                    </tr>
                    <tr>
                        <th><a href="https://www.unglobalcompact.org/" target="_blank">UNGC</a></th>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    )
}

export default ResourcesComponent;