import React, { Fragment } from 'react';
import Questionnaire from '../Models/Questionnaire';
import QuestionSection from '../Models/QuestionSection';
import QuestionSectionComponent from './QuestionSectionComponent';
import { Button, Container, Row, Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import ResultComponent from './ResultComponent';
import { NavigationSection } from '../Models/NavigationSection';
import store, { useStoreActions } from '../store';
import { useStoreState, StoreProvider } from 'easy-peasy';
import GlossaryComponent from './GlossaryComponent';
import PortfolioComponent from './PortfolioComponent';
import HelpDeskComponent from './HelpDeskComponent';
import MatrixComponent from './MatrixComponent';
import RecommendationsComponent from './RecommendationsComponent';
import ResourcesComponent from './ResourcesComponent';
import NoticeComponent from './NoticeComponent';
import ReactPDF, { Document, Page, PDFDownloadLink } from '@react-pdf/renderer';
import PdfResultComponent from './PdfResultComponent';

type QuestionnaireProps = {
    questionnaire: Questionnaire;
    currentSection: NavigationSection;
}


function QuestionnaireComponent(props: QuestionnaireProps){
    
    const changeSection = useStoreActions(actions => actions.changeSection);
    const assessment = useStoreState(state => state.assessment);
    const toggleShowGlossary = useStoreActions(actions => actions.toggleShowGlossary);
    const togglePortfolio = useStoreActions(actions => actions.togglePortfolio);
    const showingGlossary = useStoreState(state => state.showingGlossary);
    const showingPortfolio = useStoreState(state => state.showingPortfolio);
    const showingPage = useStoreState(state => state.showingPage);
    const setPage = useStoreActions(actions => actions.showPage);
    const assessments = useStoreState(state => state.assessments);
    const questionnaires = useStoreState(state => state.questionnaires);
    function onSectionSubmit(){
        const newSection = {
            qgIndex: 0,
            qsIndex: props.currentSection.qsIndex ? props.currentSection.qsIndex + 1 : 1
        };
        changeSection(newSection);
    }

    function onSelectSection(qsIndex: number, qgIndex: number){
        const newSection = {
            qsIndex,
            qgIndex
        };
        changeSection(newSection);
    }
    let numSections = 0;
    if(props.questionnaire && props.questionnaire.questionSections){
        numSections = props.questionnaire.questionSections.length;
    }

    function printPdf(){
        setPage('resultPdf');
    }

    return (
        <Fragment>
            
            <Container fluid={true}>
                <Row>
                    <Col md="4" className="non-printable">
                        <Card>
                            <CardHeader>
                        <h4>Select Section</h4>
                        </CardHeader>
                        <CardBody>
                        <ul style={{listStyleType:'none', paddingInlineStart: 0}} className="sidePanel">
                            <li ><Button color="link" onClick={() => toggleShowGlossary()} style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }}>Glossary</Button></li>
                            <li ><Button color="link" onClick={() => togglePortfolio()} style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }}>Portfolio</Button></li>
                            <hr />
                            {
                                props.questionnaire && props.questionnaire.questionSections &&
                                props.questionnaire.questionSections.map((qs, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <li key={i}><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold', color: i === props.currentSection.qsIndex && qs.qsColor ? qs.qsColor : '#000' }} color="link" onClick={() => { onSelectSection(i, 0) }}>{qs.qsTitle}</Button></li>
                                            {
                                                qs.questionGroups && (
                                                        qs.questionGroups.filter(qg => {
                                                            if(qg.questions && qg.questions[0] && (!qg.questions[0].qSectorSpecific || qg.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                                                                return true;
                                                            }
                                                            else{
                                                                return false;
                                                            }
                                                        }).map((qg, j) => {
                                                            return (
                                                            <li key={j} style={{
                                                                borderBottomStyle: 'solid',
                                                                borderBottomColor: '#bbb',
                                                                borderBottomWidth: '1px'
                                                            }}>
                                                                <Button style={{ textAlign: 'left', paddingTop: 0, paddingBottom: 4, fontWeight: props.currentSection.qgIndex === j && props.currentSection.qsIndex === i ? 'bold' : 'normal' }} color="link" onClick={() => { onSelectSection(i, j) }}>{qg.qgTitle}</Button>
                                                            </li>
                                                            )
                                                        })
                                                )
                                            }
                                            <hr />
                                        </Fragment>
                                    )
                                })
                            }
                            {   props.questionnaire !== null && props.questionnaire.questionSections &&
                                <li><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }} color="link" onClick={() => { onSelectSection(numSections, 0) }}>Results</Button></li>
                            }
                            {/* <li><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }} color="link" onClick={() => { setPage("matrix") }}>SDGs Impact Matrix</Button></li> */}
                            <li><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }} color="link" onClick={() => { setPage("recommendations") }}>Recommendations</Button></li>
                            <li><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }} color="link" onClick={() => { setPage("helpdesk") }}>Helpdesk</Button></li>
                            <li><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }} color="link" onClick={() => { setPage("resources") }}>Resources</Button></li>
                            <li><Button style={{ textAlign: 'left', paddingTop: 4, paddingBottom: 4, fontSize: '17px', fontWeight: 'bold' }} color="link" onClick={() => { setPage("notice") }}>Notice &amp; Disclaimer</Button></li>
                        </ul>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col md="8">
                    { showingGlossary && <GlossaryComponent></GlossaryComponent>}
                    { showingPortfolio && <PortfolioComponent></PortfolioComponent>}
                    { showingPage === 'helpdesk' && <HelpDeskComponent></HelpDeskComponent>}
                    { showingPage === 'matrix' && <MatrixComponent></MatrixComponent>}
                    { showingPage === 'recommendations' && <RecommendationsComponent></RecommendationsComponent>}
                    { showingPage === 'resources' && <ResourcesComponent></ResourcesComponent>}
                    { showingPage === 'notice' && <NoticeComponent></NoticeComponent>}
                    { showingPage === 'resultPdf' && <PDFDownloadLink document={<Document><PdfResultComponent assessment={assessment} assessments={assessments} questionnaires={questionnaires} /></Document>} fileName="results.pdf" />}
                    {!showingGlossary && !showingPortfolio && !showingPage && <div>
                        {/* <h4  className="non-printable">You are assessing:</h4> */}
                        <Card>
                            <CardHeader>
                                <h4  className="non-printable">{ assessment.asset.assetTitle }</h4>
                            </CardHeader>
                            <CardBody>
                            <h4  className="non-printable" style={{textDecoration: 'underline', textDecorationColor: '#65A8DD'}}>{assessment.asset.assetCategory}</h4>
                                {
                                    props.questionnaire && props.questionnaire.questionSections
                                    && props.questionnaire.questionSections.map((qs : QuestionSection, index: number) => {
                                        return ( index === props.currentSection.qsIndex &&
                                            <Fragment key={index}>
                                                <h5 style={{fontWeight: 'bold'}}>Section: <span style={{color: qs.qsColor ? qs.qsColor : '#000'}}>{qs.qsTitle}</span></h5>
                                                <hr />
                                                <QuestionSectionComponent questionSection={qs} currentSection={props.currentSection} ></QuestionSectionComponent>
                                                {props.questionnaire !== undefined && props.questionnaire.questionSections && (
                                                <Button className="btn-block btn-custom-blue" onClick={onSectionSubmit}>{index < props.questionnaire.questionSections.length-1 ? 'Next Section' :'Results'}</Button>
                                                )}
                                                <br /><br /><br />
                                            </Fragment>
                                        )
                                    })
                                }
                                {
                                    props.questionnaire && props.questionnaire.questionSections &&
                                        props.questionnaire.questionSections.length === props.currentSection.qsIndex && (
                                            <Fragment>
                                                <ResultComponent></ResultComponent>
                                            </Fragment>
                                        )
                                }
                            </CardBody>
                        </Card>
                        <br /><br />
                        
                    </div>
                }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default QuestionnaireComponent;