import React, { useEffect, Fragment } from 'react';
import { Container } from 'reactstrap';
import { useStoreState } from 'easy-peasy';

function RecommendationsComponent(){
    const assessment = useStoreState(state => state.assessment);
    const questionnaire = useStoreState(state => state.questionnaire);
    const printing = useStoreState(state => state.printing);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <Container >
        <h3 className="text-center">Recommendations</h3>
        <br />
        <Fragment>
                    <h3>Environmental</h3>
                    <table className="table table-striped">
                        <tbody>
                        {
                            assessment.answers.filter(aa => {
                                if(aa.aaSuggestion){
                                    for(let i = 0; i < questionnaire.questionSections[0].questionGroups.length; i ++){
                                        let group = questionnaire.questionSections[0].questionGroups[i];
                                        for(let j = 0; j < group.questions.length; j ++){
                                            let q = group.questions[j];
                                            if(q.qId === aa.qId){
                                                return true;
                                            }
                                        }
                                    }
                                } 
                                return false;
                            }).map((aa, k) => {
                                return <tr key={k}><td>{aa.aaSuggestion}</td></tr>
                            })
                        }
                        </tbody>
                    </table>
                    <h3>Social</h3>
                    <table className="table table-striped">
                        <tbody>
                        {
                            assessment.answers.filter(aa => {
                                if(aa.aaSuggestion){
                                    for(let i = 0; i < questionnaire.questionSections[1].questionGroups.length; i ++){
                                        let group = questionnaire.questionSections[1].questionGroups[i];
                                        for(let j = 0; j < group.questions.length; j ++){
                                            let q = group.questions[j];
                                            if(q.qId === aa.qId){
                                                return true;
                                            }
                                        }
                                    }
                                } 
                                return false;
                            }).map((aa, k) => {
                                return <tr key={k}><td>{aa.aaSuggestion}</td></tr>
                            })
                        }
                        </tbody>
                    </table>
                    <h3>Governance</h3>
                    <table className="table table-striped">
                        <tbody>
                        {
                            assessment.answers.filter(aa => {
                                if(aa.aaSuggestion){
                                    for(let i = 0; i < questionnaire.questionSections[2].questionGroups.length; i ++){
                                        let group = questionnaire.questionSections[2].questionGroups[i];
                                        for(let j = 0; j < group.questions.length; j ++){
                                            let q = group.questions[j];
                                            if(q.qId === aa.qId){
                                                return true;
                                            }
                                        }
                                    }
                                } 
                                return false;
                            }).map((aa, k) => {
                                return <tr key={k}><td>{aa.aaSuggestion}</td></tr>
                            })
                        }
                        </tbody>
                    </table>
            </Fragment>
        
    </Container>
}

export default RecommendationsComponent;