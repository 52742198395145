
export class AssessmentAnswer {

    aaId?: number;
    asId?: number;
    aaAnswer?: string;
    userIdAnswered?: number;
    aaSector?: string;
    aaImpact?: number;
    aaComment?: string;
    aaFiles?: string;
    aaFilesUpload?: FileList;
    qId?: number;
    userIdReanswered?: number;
    aaSuggestion?: string;
}
