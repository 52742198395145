import React, { Fragment } from 'react';

function NoticeComponent(){


    return (
        <Fragment>
            <h2>Notice and Disclaimer</h2>
            <p>This ESG Rating tool (the Product) has been created and developed for the Hellenic Republic
                Asset Development Fund SA (HRADF), which is the beneficiary of the Product. The creation
                and development of the Product has been funded by the European Bank for Reconstruction
                and Development (EBRD). Technical and advisory support for this Product has been received
                by the Global Sustain Group. The digital adaptation of the technical requirements of the
                Product to a cloud-based application has been implemented by digital agency Think Plus.
                HRADF, EBRD, Global Sustain Group and Think Plus are collectively referred to as the Parties.</p>
            <p>
                The Product is not a credit rating. This Product provides an analysis of an entity’s capacity to
                identify and manage environmental and social issues successfully at the time of running the
                analysis and is grounded in how Environmental, Social and Governance (ESG) factors could
                affect stakeholders’ perception towards this entity and potentially lead to a material direct
                or indirect impact on the entity.
            </p>
            <p>
                ESG factors typically assess the impact of the entity or its business activity on the natural and
                social environment and the quality of its governance. The definition of stakeholders for a
                particular entity goes beyond shareholders to include other groups as appropriate such as
                employees, the local community, investors, government, regulators, customers, lenders,
                borrowers, policyholders and suppliers. The Product is not a research report and is not
                intended as such.
            </p>
            <p>
                The Product and the outputs of the Product do not constitute investment advice,
                recommendations regarding credit decisions, recommendations to purchase, hold, or sell
                any securities or to make any investment decisions, an offer to buy or sell or the solicitation
                of an offer to buy or sell any security, endorsements of the suitability of any security,
                endorsements of the accuracy of any data or conclusions provided in the Product, or
                independent verification of any information relied upon in the credit rating process.
            </p>
            <p>
                The Product and any associated presentations do not take into account any user’s financial
                objectives, financial situation, needs or means, and should not be relied upon by users for
                making any investment decisions. The output of the Product is not a substitute for a user’s
                independent judgment and expertise. The output of the Product is not professional financial,
                tax or legal advice, and users should obtain independent, professional advice, as it is
                determined necessary by users.
            </p>
            <p>
                To verify the input data to the Product, the user is advised to perform a due diligence and/or
                an audit and/or an independent verification of any information related to this Product.
            </p>
            <p>
                The Parties, as well as their directors, officers, shareholders, employees or agents do not
                guarantee the accuracy, completeness, timeliness or availability of the Product. The Parties
                are not responsible for any errors or omissions (negligent or otherwise), regardless of the
                cause, for reliance of use of information in the Product, or for the security or maintenance of
                any information transmitted via the Internet, or for the accuracy of the information in the
                Product.
            </p>
            <p>
                The Product is provided on an “AS IS” basis. PARTIES MAKE NO REPRESENTATION OR
                WARRANTY, EXPRESS OR IMPLIED, INCLUDED BUT NOT LIMITED TO, THE ACCURACY,
                RESULTS, TIMELINESS, COMPLETENESS, MERCHANTABILITY OR FITNESS FOR ANY
                PARTICULAR PURPOSE WITH RESPECT TO THE PRODUCT, OR FOR THE SECURITY OF THE
                WEBSITE FROM WHICH THE PRODUCT IS ACCESSED.
            </p>
            <p>
                The Parties have no responsibility to maintain or update the Product or to supply any
                corrections, updates or releases in connection therewith. The Parties have no liability for the
                accuracy, timeliness, reliability, performance, continued availability, completeness or delays,
                omissions, or interruptions in the delivery of the Product.
            </p>
            <p>
                To the extent permitted by law, in no event shall the Parties be liable to any party for any
                direct, indirect, incidental, exemplary, compensatory, punitive, special or consequential
                damages, costs, expenses, legal fees, or losses (including, without limitation, lost income or
                lost profits and opportunity costs or losses caused by negligence, loss of data, cost of
                substitute materials, cost of capital, or claims of any third party) in connection with any use
                of the Product even if advised of the possibility of such damages.
            </p>
            <h4>About HRADF</h4>
            <p>
                According to its founding Law No. 3986/2011, the Hellenic Republic Asset Development
                Fund (HRADF) leverages the State private property assigned to it by Hellenic Republic. In
                close cooperation with the Greek Government, HRADF promotes the implementation of
                privatizations in the country, having full responsibility for the application of the respective
                policy. The materialization of the HRADF’s privatization program, already in progress, has as
                sole mission the maximization of development by attracting direct investments in
                infrastructures, energy, real estate and other fields, while achieving additional important
                benefits, such as job creation, infrastructure modernization and promotion of the necessary
                reforms. For more information please visit www.hradf.gr.
            </p>
            <h4>About the European Bank for Reconstruction and Development</h4>
            <p>
                The European Bank for Reconstruction and Development (EBRD) is an international financial
                institution founded in 1991. A clearly defined set of standards governs all EBRD work as it
                strives to develop a sound investment climate and promote environmentally and socially
                sound and sustainable development. As a multilateral developmental investment bank, the
                EBRD uses investment as a tool to build market economies. Headquartered in London, UK,
                the EBRD is owned by 69 countries and two EU institutions. Despite its public sector
                shareholders, it invests in private enterprises, together with commercial partners. EBRD
                invests in changing lives. Through its projects, business services and involvement in high-
                level policy reform, EBRD is doing more than ever before across three continents. Everything
                it does pursues the goal of advancing the transition to open, market economies, whilst
                fostering sustainable and inclusive growth. EBRD is active in 38 economies across three
                continents, from the Southern and Eastern Mediterranean, to Central and Eastern Europe,
                to Central Asia. EBRD operations span a range of industries, from agribusiness to
                infrastructure to transport. Thanks to EBRD’s Green Economy Transition approach the bank
                is a world leader in climate finance. For more information please visit www.ebrd.com.
            </p>
            <h4>About the Global Sustain Group</h4>
            <p> 
                Global Sustain Group is an international sustainability management consulting firm
                established in 2006, with experience in ESG Responsible Investing, Sustainable Finance,
                Sustainability and Corporate Responsibility, Compliance, Reporting, Strategy and Risk. Global
                Sustain Group is community driven with 400+ organisations as members. Headquarters are
                in London, with companies in Berlin and in Athens, an office in Brussels, and affiliates in New
                York and Cyprus. Global Sustain creates awareness and inspires and supports companies and
                organisations to embody sustainability, through advisory, communications, networking and
                training, with a focus on the people-planet-profit philosophy. Its members include
                corporations, non-governmental and non-profit organisations, municipalities and local
                authorities, educational foundations, media, professional bodies, think tanks and other
                public or private entities. Global Sustain is a signatory to the Ten Principles of the UN Global
                Compact, to the Principles for Responsible Investment (PRI), a Global Reporting Initiative
                (GRI) Data Partner and Organisational Stakeholder (OS), an affiliated member of the
                Academy of Business in Society (ABIS), Social Value International, Institute of Directors, CEO
                Clubs and the Task Force for Climate-related Financial Disclosures (TCFD). For more
                information please visit www.globalsustaingroup.com.
            </p>
            <h4>About Think Plus</h4>
            <p>
                Think Plus is an international digital agency with offices in Germany and Greece and an
                expertise in web design and development, online marketing, and above and below the line
                productions. A multi-awarded and ISO-certified app development company, Think Plus
                builds B2B and B2C custom solutions for specialised business requirements providing high
                quality user experience results. Think Plus is a software development company using all
                modern available technologies, user friendly content management systems and responsive
                layouts for tablets and smartphones. For more info please visit www.think-plus.gr.
            </p>
            <p>All rights reserved.</p>

        </Fragment>
    )
}

export default NoticeComponent;