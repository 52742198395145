import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useStoreState } from 'easy-peasy';

function MatrixComponent(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const assessments = useStoreState(state => state.assessments);
    return <Container >
    <h3 className="text-center">SDGs Impact Matrix</h3>
    <br />
    <table className="table table-striped table-bordered">
        <thead>
            <tr>
                <th colSpan={18} style={{textAlign: 'center'}}>
                    Sustainable Development Goals (SDGs) Impact Matrix
                </th>
            </tr>
        <tr>
            <th >
                Asset
            </th>
            <td >
                1
            </td>
            <td >
                2
            </td>
            <td >
                3
            </td>
            <td >
                4
            </td>
            <td >
                5
            </td>
            <td >
                6
            </td>
            <td >
                7
            </td>
            <td >
                8
            </td>
            <td >
                9
            </td>
            <td >
                10
            </td>
            <td >
                11
            </td>
            <td >
                12
            </td>
            <td >
                13
            </td>
            <td >
                14
            </td>
            <td >
                15
            </td>
            <td >
                16
            </td>
            <td >
                17
            </td>
        </tr>
        </thead>
        <tbody>
            {assessments.map(a => {
                return <tr>
                    <td style={{whiteSpace: 'nowrap'}}>{ a.asset.assetTitle }</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            })}
        </tbody>
    </table>
</Container>
}

export default MatrixComponent;