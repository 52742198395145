import React, { Fragment, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { Row, Col, Button, Container } from 'reactstrap';
import { Assessment } from '../Models/Assessment';
import { Bar, Pie } from 'react-chartjs-2';
import { useStoreActions } from '../store';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../App.css';
import RecommendationsComponent from './RecommendationsComponent';

function ResultComponent(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const assessment = useStoreState(state => state.assessment);
    const assessments = useStoreState(state => state.assessments);
    const questionnaires = useStoreState(state => state.questionnaires);
    const changeSection = useStoreActions(actions => actions.changeSection);
    const printing = useStoreState(state => state.printing);
    const startPrinting = useStoreActions(actions => actions.startPrinting);
    const stopPrinting = useStoreActions(actions => actions.stopPrinting);

    function onSelectSection(qsIndex: number, qgIndex: number){
        const newSection = {
            qsIndex,
            qgIndex
        };
        changeSection(newSection);
    }
    
    class ResultType{
        qsTitle?: string;
        qsMaxImpact?: number;
        qsId?: number;
    }

    class Results{
        resultType?: ResultType;
        rScore?: number;
    }
    let questionsUnanswered = 0;
    let totalQuestions = 0;

    function calculateResult(assessment: Assessment) : Results[]{
        const qair = questionnaires.filter(q => q.qairId === assessment.qairId)[0];
        const results: Array<Results> = [];
        if(qair){
            for(let i = 0; i < qair.questionSections.length; i ++){
                const rt = new ResultType();
                let mr = 0;
                const questionSection = qair.questionSections[i];
                rt.qsId = questionSection.qsId;
                rt.qsTitle = questionSection.qsTitle;
                for(let j = 0; j < questionSection.questionGroups.length; j ++){
                    const questionGroup = questionSection.questionGroups[j];
                    if(questionGroup.questions && questionGroup.questions[0] && assessment && assessment.asset && assessment.asset.assetCategory &&  (!questionGroup.questions[0].qSectorSpecific || questionGroup.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                        totalQuestions += questionGroup.questions.length;
                        for(let l = 0; l < questionGroup.questions.length; l++){
                            const q = questionGroup.questions[l];
                            mr += q.qMaxImpact;
                        }
                    }
                }
                rt.qsMaxImpact = mr;
                const res = new Results();
                res.resultType = rt;
                const answers = assessment.answers.filter(a => a.aaSector === rt.qsTitle);
                let r = 0;
                for(let k = 0; k < answers.length; k ++){
                    const a = answers[k];
                    if(a.aaAnswer === '||NOTAPPLICABLE||'){
                        for(let j = 0; j < questionSection.questionGroups.length; j ++){
                            const questionGroup2 = questionSection.questionGroups[j];
                            for(let l = 0; l < questionGroup2.questions.length; l++){
                                let q = questionGroup2.questions[l];
                                if(q.qId === a.qId){
                                    rt.qsMaxImpact -= q.qMaxImpact;
                                }
                            }
                        }
                    }
                    else if(a.aaImpact){
                        r += a.aaImpact;
                    }
                }
                res.rScore = r;
                results.push(res);
            }
            return results;
        }   
        else{
            return [];
        } 
    }
    const results = calculateResult(assessment);
    questionsUnanswered = (totalQuestions - assessment.answers.filter(a => a.qId).length);
    let overallScore: number = 0;
    for(let i = 0; i < results.length; i ++){
        const res = results[i];
        let impact;
        if(i === 0){
            impact = 0.4;
        }
        if(i === 1){
            impact = 0.35;
        }
        if(i === 2){
            impact = 0.25;
        }
        if(res && res.resultType){
            if(res.resultType.qsMaxImpact){
                overallScore += ((res.rScore as number / res.resultType.qsMaxImpact) * 100) * impact;
            }
        }
    }

    const categoryResults: Results[][] = [];
    let categoryAssessments = assessments.filter(ass => ass.asset.assetCategory === assessment.asset.assetCategory)
    for(let i = 0; i < categoryAssessments.length; i ++){
        categoryResults.push(calculateResult(categoryAssessments[i]));
    }

    const allResults: Results[][] = [];
    for(let i = 0; i < assessments.length; i ++){
        allResults.push(calculateResult(assessments[i]));
    }

    let haveUnanswered: {qsIndex: number, qgIndex: number, qsTitle: string, qgTitle: string}[] = [];
    let notApplicableCounter = assessment.answers.filter(a => a.aaAnswer === '||NOTAPPLICABLE||').length;
    const qair = questionnaires.filter(q => q.qairId === assessment.qairId)[0];
    for(let t = 0; t < qair.questionSections.length; t ++){
        const section = qair.questionSections[t];
        for(let g = 0; g < section.questionGroups.filter((qg, l) => {
            if(qg.questions && qg.questions[0] && (!qg.questions[0].qSectorSpecific || qg.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                return true;
            }
            else{
                return false;
            }
        }).length; g++){
            const group = section.questionGroups.filter(qg => {
                if(qg.questions && qg.questions[0] && (!qg.questions[0].qSectorSpecific || qg.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                    return true;
                }
                else{
                    return false;
                }
            })[g];
            if(group.questions && group.questions[0] && assessment && assessment.asset && assessment.asset.assetCategory &&  (!group.questions[0].qSectorSpecific || group.questions[0].qSectorSpecific === assessment.asset.assetCategory )){
                for(let f = 0; f < group.questions.length; f++){
                    const q = group.questions[f];
                    if(assessment.answers.filter(a => a.qId === q.qId).length === 0){
                        haveUnanswered.push({qsIndex: t, qgIndex: g, qsTitle: section.qsTitle, qgTitle: group.qgTitle});
                        break;
                    }
                }
            }
        }
    }

    function exportPdf(){
        startPrinting();
        setTimeout(() => {

            window.print();

            setTimeout(() => {
                stopPrinting();
            }, 1000);
        }, 1000);
        
    }

    // function exportPdfx(){
    //     if(document.querySelector('#resultsComponent')){
    //         startPrinting();
    //         setTimeout(() => {
    //             const pdf = new jsPDF();
    //             html2canvas(document.querySelector('#page1') as HTMLElement).then(canvas => {
    //                 const imgData = canvas.toDataURL('image/png');
    //                 //document.body.appendChild(canvas);
                    
    //                 //var width = pdf.internal.pageSize.getWidth();
    //                 pdf.addImage(imgData, 'PNG', 0, 0);
    //                 html2canvas(document.querySelector('#page2') as HTMLElement).then(canvas2 => {
    //                     pdf.addPage();
    //                     //document.body.appendChild(canvas2);
    //                     const imgData2 = canvas2.toDataURL('image/png');
                    
    //                 //var width = pdf.internal.pageSize.getWidth();
    //                     pdf.addImage(imgData2, 'PNG', 0, 0, 210, 290);
    //                     html2canvas(document.querySelector('#page3') as HTMLElement).then(canvas3 => {
    //                         //document.body.appendChild(canvas3);
    //                         pdf.addPage();
    //                         const imgData3 = canvas3.toDataURL('image/png');
                        
    //                     //var width = pdf.internal.pageSize.getWidth();
    //                         pdf.addImage(imgData3, 'PNG', 0, 0);
    //                         pdf.addPage();
    //                         html2canvas(document.querySelector('#page4') as HTMLElement).then(canvas4 => {
    //                             const imgData4 = canvas4.toDataURL('image/png');
                        
    //                             pdf.addImage(imgData4, 'PNG', 0, 0);
    //                             pdf.addPage();
    //                             html2canvas(document.querySelector('#page5') as HTMLElement).then(canvas5 => {
    //                                 const imgData5 = canvas5.toDataURL('image/png');
                            
    //                                 pdf.addImage(imgData5, 'PNG', 0, 0);
    //                                 pdf.addPage();
    //                                 html2canvas(document.querySelector('#page6') as HTMLElement).then(canvas6 => {
    //                                     const imgData6 = canvas6.toDataURL('image/png');
                                
    //                                     pdf.addImage(imgData6, 'PNG', 0, 0);
    //                                     pdf.save(assessment.asset.assetTitle + '.pdf');
    //                                     setTimeout(() => {
    //                                         stopPrinting();
    //                                     }, 1000);
    //                                 });
    //                             });
    //                         });
    //                     });
    //                 });
    //             });
    //         }, 1000);
            
    //     }
        
        
    // }

    console.log(calculateResult(assessments[4]))
    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <button className="btn btn-primary btn-sm float-right non-printable" onClick={exportPdf}>Export to PDF</button>
                </Col>
            </Row>
            <Container id="resultsComponent" className="printable">
                <img style={{ display: printing ? 'block' : 'none' }} src={'logo.jpg'} />
                <h2 className="text-center">Your ESG Rating Score</h2>
                <table className="table table-striped table-hover">
                    <thead style={{background: '#65A8DD'}}>
                        <tr>
                            <th colSpan={5}>
                                <h4 className="text-center">Your E, S, G and Overall Score</h4>
                            </th>
                        </tr>
                        <tr>
                            <th>Asset</th>
                            <th className="text-center">Environmental<br />Rating</th>
                            <th className="text-center">Social<br />Rating</th>
                            <th className="text-center">Governance<br />Rating</th>
                            <th className="text-center">ESG<br />Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{assessment.asset.assetTitle}</th>
                            {
                                results.map((r, i) =>  {return  r.resultType && 
                                                            r.resultType.qsMaxImpact &&
                                                            r.rScore!== undefined && 
                                                            (<td className="text-center" key={i}>{((r.rScore/r.resultType.qsMaxImpact) * 100).toFixed(0)}</td>) })
                                }
                            <td className="text-center">{Math.round(overallScore)}</td>
                        </tr>
                    </tbody>
                </table>
                <Row>
                    <Col md={4}>

                    </Col>
                    <Col md={4}>
                        <Pie width={500} height={500} data={{
                            labels: ['Environmental Rating', 'Social Rating', 'Governance Rating'],
                            datasets: [{data: [results[0].resultType && 
                                results[0].resultType.qsMaxImpact &&
                                results[0].rScore!== undefined ? parseInt(((results[0].rScore/results[0].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                                results[1].resultType && 
                                results[1].resultType.qsMaxImpact &&
                                results[1].rScore!== undefined ? parseInt(((results[1].rScore/results[1].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                                results[2].resultType && 
                                results[2].resultType.qsMaxImpact &&
                                results[2].rScore!== undefined ? parseInt(((results[2].rScore/results[2].resultType.qsMaxImpact) * 100).toFixed(0)) : 0
                            ], backgroundColor: ['#0f0', '#00f', '#666']}]
                        }}></Pie>
                    </Col>
                    <Col md={4}>
                        
                    </Col>
                </Row>
                <Row>
                    <Col md="4" style={{borderRightColor: '#000', borderRightStyle: 'solid', borderRightWidth: '1px'}}>
                        {results[0].rScore && results[0].resultType && results[0].resultType.qsMaxImpact && (
                            <Bar width={250} height={200} data={{
                                    labels: ['Environmental Rating'],
                                    datasets: [
                                        {
                                            label: 'Target',
                                            data: [100]
                                        },
                                        {
                                            label: 'Rating',
                                            backgroundColor: '#65A8DD',
                                            data: [parseInt(((results[0].rScore/results[0].resultType.qsMaxImpact)*100).toFixed(0))]
                                        },
                                        {
                                            label: 'Weighted',
                                            backgroundColor: '#95D8FD',
                                            data: [parseInt((((results[0].rScore/results[0].resultType.qsMaxImpact)*100)*0.4).toFixed(0))]
                                        }
                                    ],

                                }} options={{
                                    xAxes: [{
                                        display: true
                                    }],
                                    yAxes: [{
                                            display: true,
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                max: 100,
                                                stepSize: 1
                                            }
                                        }]
                                }}>
                            </Bar>
                        )}
                    </Col>
                    <Col md="4" style={{borderRightColor: '#000', borderRightStyle: 'solid', borderRightWidth: '1px'}}>
                        {results[1].rScore && results[1].resultType && results[1].resultType.qsMaxImpact && (
                            <Bar width={250} height={200} data={{
                                labels: ['Social Rating'],
                                datasets: [
                                    {
                                        label: 'Target',
                                        data: [100]
                                    },
                                    {
                                        label: 'Rating',
                                        backgroundColor: '#65A8DD',
                                        data: [parseInt(((results[1].rScore/results[1].resultType.qsMaxImpact)*100).toFixed(0))]
                                    },
                                    {
                                        label: 'Weighted',
                                        backgroundColor: '#95D8FD',
                                        data: [parseInt((((results[1].rScore/results[1].resultType.qsMaxImpact)*100)*0.35).toFixed(0))]
                                    }
                                ],

                            }} options={{
                                xAxes: [{
                                    display: true
                                }],
                                yAxes: [{
                                        display: true,
                                        ticks: {
                                            min: 0,
                                            beginAtZero: true,
                                            max: 100,
                                            stepSize: 1
                                        }
                                    }]
                            }}></Bar>
                        )}
                    </Col>
                    <Col md="4">
                        {results[2].rScore && results[2].resultType && results[2].resultType.qsMaxImpact && (
                            <Bar width={250} height={200} data={{
                                labels: ['Governance Rating'],
                                datasets: [
                                    {
                                        label: 'Target',
                                        data: [100]
                                    },
                                    {
                                        label: 'Rating',
                                        backgroundColor: '#65A8DD',
                                        data: [parseInt(((results[2].rScore/results[2].resultType.qsMaxImpact)*100).toFixed(0))]
                                    },
                                    {
                                        label: 'Weighted',
                                        backgroundColor: '#95D8FD',
                                        data: [parseInt((((results[2].rScore/results[2].resultType.qsMaxImpact)*100)*0.25).toFixed(0))]
                                    }
                                ],

                            }} options={{
                                xAxes: [{
                                    display: true
                                }],
                                yAxes: [{
                                        display: true,
                                        ticks: {
                                            min: 0,
                                            beginAtZero: true,
                                            max: 100,
                                            stepSize: 1
                                        }
                                    }]
                            }}></Bar>
                        )}
                    </Col>
                </Row>
                <p style={{ display: printing ? 'block' : 'none', textAlign: 'right' }}>Page 2</p>
                <table className="table table-striped table-hover">
                    <thead style={{background: '#65A8DD'}}>
                        <tr>
                            <th colSpan={5}>
                                <h4 className="text-center">{assessment.asset.assetCategory} Ratings</h4>
                            </th>
                        </tr>
                        <tr>
                            <th>Asset</th>
                            <th className="text-center">Environmental<br />Rating</th>
                            <th className="text-center">Social<br />Rating</th>
                            <th className="text-center">Governance<br />Rating</th>
                            <th className="text-center">ESG<br />Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{assessment.asset.assetTitle}</th>
                            {
                                results.map((r, i) =>  {return  r.resultType && 
                                                            r.resultType.qsMaxImpact &&
                                                            r.rScore !== undefined && 
                                                            (<th className="text-center" key={i}>{((r.rScore/r.resultType.qsMaxImpact) * 100).toFixed(0)}</th>) })
                                }
                            <th className="text-center">{overallScore.toFixed(0)}</th>
                        </tr>
                        {
                            categoryResults.map((results, i) => {
                                if(categoryAssessments[i].asset.assetId !== assessment.asset.assetId){
                                return (
                                    <tr key={i}>
                                        <th>{ categoryAssessments[i] && categoryAssessments[i].asset.assetTitle }</th>
                                        {
                                            results.map((r, j) =>  {return  r.resultType && 
                                                                        r.resultType.qsMaxImpact &&
                                                                        r.rScore !== undefined && 
                                                                        (<td className="text-center" key={j}>{((r.rScore/r.resultType.qsMaxImpact) * 100).toFixed(0)}</td>) })
                                        }
                                        {
                                            <th className="text-center">{
                                                results && results[0] && results[0].rScore !== undefined && results[0].resultType &&
                                                results[0].resultType.qsMaxImpact && results[1] && results[1].rScore !== undefined && 
                                                results[1].resultType && results[1].resultType.qsMaxImpact 
                                                && results[2].rScore !== undefined && results[2] && results[2].resultType && 
                                                results[2].resultType.qsMaxImpact && 
                                                ((((results[0].rScore / results[0].resultType.qsMaxImpact) * 100 ) * 0.4+ 
                                                        ((results[1].rScore / results[1].resultType.qsMaxImpact) * 100) * 0.35 + 
                                                        ((results[2].rScore / results[2].resultType.qsMaxImpact) * 100) * 0.25) ).toFixed(0)}
                                            </th>
                                        }
                                    </tr>
                                )
                                }
                            })
                        }
                    </tbody>
                </table>
                <table className="table table-striped table-hover">
                    <thead style={{background: '#65A8DD'}}>
                        <tr>
                            <th colSpan={5}>
                                <h4 className="text-center">All Ratings</h4>
                            </th>
                        </tr>
                        <tr>
                            <th>Asset</th>
                            <th className="text-center">Environmental<br />Rating</th>
                            <th className="text-center">Social<br />Rating</th>
                            <th className="text-center">Governance<br />Rating</th>
                            <th className="text-center">ESG<br />Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{assessment.asset.assetTitle}</th>
                            {
                                results.map((r, i) =>  {return  r.resultType && 
                                                            r.resultType.qsMaxImpact &&
                                                            r.rScore !== undefined && 
                                                            (<th className="text-center" key={i}>{((r.rScore/r.resultType.qsMaxImpact) * 100).toFixed(0)}</th>) })
                                }
                            <th className="text-center">{overallScore.toFixed(0)}</th>
                        </tr>
                        {
                            allResults.map((results, i) => {
                                if(assessments[i].asset.assetId !== assessment.asset.assetId){
                                return (
                                    <tr key={i}>
                                        <th>{ assessments[i] && assessments[i].asset.assetTitle }</th>
                                        {
                                            results.map((r, j) =>  {return  r.resultType && 
                                                                        r.resultType.qsMaxImpact &&
                                                                        r.rScore !== undefined && 
                                                                        (<td className="text-center" key={j}>{((r.rScore/r.resultType.qsMaxImpact) * 100).toFixed(0)}</td>) })
                                        }
                                        {
                                            <th className="text-center">{
                                                results && results[0] && results[0].rScore !== undefined && results[0].resultType &&
                                                results[0].resultType.qsMaxImpact && results[1] && results[1].rScore !== undefined && 
                                                results[1].resultType && results[1].resultType.qsMaxImpact 
                                                && results[2].rScore !== undefined && results[2] && results[2].resultType && 
                                                results[2].resultType.qsMaxImpact && 
                                                ((((results[0].rScore / results[0].resultType.qsMaxImpact) * 100 ) * 0.4+ 
                                                        ((results[1].rScore / results[1].resultType.qsMaxImpact) * 100) * 0.35 + 
                                                        ((results[2].rScore / results[2].resultType.qsMaxImpact) * 100) * 0.25)).toFixed(0)}
                                            </th>
                                        }
                                    </tr>
                                )
                                }
                            })
                        }
                    </tbody>
                </table>
                
                <p style={{ display: printing ? 'block' : 'none', textAlign: 'right' }}>Page 3</p>
                <p style={{ fontWeight: 'bold' }}>{ notApplicableCounter > 0 && notApplicableCounter + " questions have been marked as Not Applicable" }</p>
                <p style={{ fontWeight: 'bold' }}>{questionsUnanswered} questions have not been answered</p>
                {questionsUnanswered > 0 && <Fragment><h4>There are unanswered questions in:</h4>
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Section-Group</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            haveUnanswered.map((hu, i) => {
                                return (
                                    <tr key={i}>
                                        <td><b>{hu.qsTitle}</b> - {hu.qgTitle}</td>
                                        <td>
                                            <Button color="primary" onClick={() => { onSelectSection(hu.qsIndex, hu.qgIndex) }}>Go</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table></Fragment>}
                
                </Container>
            {printing && (
                <RecommendationsComponent></RecommendationsComponent>
            )}
        </Fragment>
    );

}

export default ResultComponent;