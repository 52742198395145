import React, {  Fragment, useEffect } from 'react';
import QuestionGroup from '../Models/QuestionGroup';
import Question from '../Models/Question';
import QuestionComponent from './QuestionComponent';

type QuestionGroupProps = {
    questionGroup: null | QuestionGroup;
};

function QuestionGroupComponent(props: QuestionGroupProps){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <h6 style={{ fontWeight: props.questionGroup && props.questionGroup.questions && 
                    props.questionGroup.questions[0] && props.questionGroup.questions[0].qSectorSpecific ? 'bold' : 'normal',
                    background: props.questionGroup && props.questionGroup.questions && 
                    props.questionGroup.questions[0] && props.questionGroup.questions[0].qSectorSpecific ? 'yellow' : '#fff',
                    padding: '10px 10px'
                         }}>{props.questionGroup && props.questionGroup.qgTitle}</h6>
            {
                props.questionGroup && props.questionGroup.questions && 
                props.questionGroup.questions.map((q: Question, i: number) => {
                    return (
                        <Fragment key={q.qId}>
                            <QuestionComponent qId={q.qId as number} question={q}></QuestionComponent>
                        </Fragment>
                    )
                })
            }
        </Fragment>
    )
}

export default QuestionGroupComponent;