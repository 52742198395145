import React from 'react';
import { Container, Button, FormGroup, Row, Col, Input, Alert } from 'reactstrap';
import { useStoreActions } from "../store";
import { useStoreState } from 'easy-peasy';
import { QuestionnaireService } from '../Services/QuestionnaireService';

function LoginScreen(){
    const questionnaireService = new QuestionnaireService();
    const loginErrorMessage = useStoreState(state => state.loginErrorMessage);
    const setLoggedIn = useStoreActions(actions => actions.setLoggedIn);
    const setLoggedInUser = useStoreActions(actions => actions.setLoggedInUser);
    
    let email = '';
    let password = '';
    
    function onEmailChanged(s: string){
        email = s;
    }
    function onPasswordChanged(s: string){
        password = s;
    }

    const setLoginErrorMessage = useStoreActions(actions => actions.setLoginErrorMessage);

    function login(){
        try{
            questionnaireService.login(email, password).then(result => {
                setLoggedInUser(result);
                setLoggedIn(true);
                
            });
        }catch(e){
            setLoginErrorMessage(e);
            setTimeout(() => {
                setLoginErrorMessage('');
            }, 3000);
        }
    }

    function resetPassword(){
        if(!email){
            setLoginErrorMessage('Please type your email first');
            setTimeout(() => {
                setLoginErrorMessage('');
            }, 3000);
        }
        else{
            if(window.confirm('Are you sure you want to reset your password?')){
                questionnaireService.resetPassword(email).then((result) => {
                    if(result){
                        setLoginErrorMessage('Your new password has been sent to your email');
                        setTimeout(() => {
                            setLoginErrorMessage('');
                        }, 3000);
                    }
                }).catch(e => {
                    setLoginErrorMessage('Something went wrong, please try again');
                    setTimeout(() => {
                        setLoginErrorMessage('');
                    }, 3000);
                });
            }
        }
    }

    return (
        <Container style={{minHeight: '60vh'}}>
            <h3 className="text-center">Login to ESG Rating</h3>
            <br />
            <Row >
                <Col md={4}></Col>
                <Col md={4} style={{background: 'rgba(0, 0, 0, 0.1)', padding: '20px'}} className="text-center">
                    {
                        loginErrorMessage && <Alert color="danger">{loginErrorMessage}</Alert>
                    }
                    <br />
                    <FormGroup>
                        <Input type="email" placeholder="E-mail" onChange={(e) => {onEmailChanged(e.currentTarget.value)}} />
                    </FormGroup>
                    <FormGroup>
                        <Input type="password" placeholder="Password" onChange={(e) => {onPasswordChanged(e.currentTarget.value)}} />
                    </FormGroup>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Button className="btn-custom-blue btn-block" onClick={login}>Login</Button>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Button className="btn-danger btn-block" onClick={resetPassword}>Reset Password</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    
                </Col>
                <Col md={4}></Col>
            </Row>
        </Container>
    )
}

export default LoginScreen;