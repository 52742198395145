// tslint:disable:no-console
import React, { Fragment, useEffect } from 'react';
import Question from '../Models/Question';
import Answer from '../Models/Answer';
import { Row, Col, Button, Label, Input, Card } from 'reactstrap';
import { useStoreActions } from '../store';
import { LiveAnswer } from '../state';

import { useStoreState } from 'easy-peasy';
import { AssessmentAnswer } from '../Models/AssessmentAnswer';
import { QuestionnaireService } from '../Services/QuestionnaireService';

type QuestionProps = {
    question: Question,
    qId: number,
};


function QuestionComponent(props: QuestionProps){
    
    const answerQuestion = useStoreActions(actions => actions.answerQuestion);
    // const filesUrl = 'http://filemanager.ikapps.gr/';
    const addLiveAnswer = useStoreActions(actions => actions.liveAnswer);
    const answers = useStoreState(state => state.assessment.answers);
    const savedAnswer = answers ? answers.filter((an) => an.qId === props.qId)[0] : null;
    let liveAnswer = useStoreState(state => state.liveAnswers.filter((la) => { return la.qId === props.qId } ))[0];
    const assessment = useStoreState(state => state.assessment);
    const currentSection = useStoreState(state => state.currentSection);
    const questionnaire = useStoreState(state => state.questionnaire);
    const questionnaireService = new QuestionnaireService();
    let activeAnswer: number = -1;
    let activeYesNo: string = '';
    let textValue: string = '';
    let rangeValue: number = 0;
    let multipleValue: number[] = [];
    let commentValue: string = '';
    let filesValue: FileList;
    const filesUrl = "https://esgfiles.thinkplus.dev/uploads/";
    

    useEffect(() => {
        if(savedAnswer){
            if(!liveAnswer){
                liveAnswer = new LiveAnswer();
            }
            liveAnswer.commentValue = savedAnswer.aaComment;
            liveAnswer.qId = savedAnswer.qId;
            if(props.question.qType === 'options' && props.question.answers){
                for(let i = 0; i < props.question.answers.length; i ++){
                    if(props.question.answers[i].aText === savedAnswer.aaAnswer){
                        liveAnswer.activeAnswer = i;
                    }
                }
            }
            if(props.question.qType === 'yesno'){
                liveAnswer.activeYesNo = savedAnswer.aaAnswer;
            }
            if(props.question.qType === 'multipleOptions' && props.question.answers){
                console.log(savedAnswer.aaAnswer)
                const aaa = savedAnswer.aaAnswer.split(',');
                for(let i = 0; i < props.question.answers.length; i ++){
                    if(savedAnswer.aaAnswer.includes(props.question.answers[i].aText)){
                        if(!liveAnswer.multipleValue){
                            liveAnswer.multipleValue = [];
                        }
                        liveAnswer.multipleValue.push(i);
                    }
                }
            }
            if(props.question.qType === 'text'){
                liveAnswer.textValue = savedAnswer.aaAnswer;
            }
            if(props.question.qType === 'range' || props.question.qType === 'percentage'){
                liveAnswer.rangeValue = parseInt(savedAnswer.aaAnswer);
            }
            console.log(liveAnswer);
            addLiveAnswer(liveAnswer);
        }
    }, []);
    
    function saveAnswer(){
        const aa = new AssessmentAnswer();
        console.log('comment: ' + commentValue);
        aa.aaComment = liveAnswer.commentValue;
        aa.qId = props.question.qId;
        aa.aaFilesUpload = liveAnswer.filesValue;
        aa.userIdAnswered = parseInt(localStorage.getItem('userId') as string);
        aa.asId = assessment.asId;
        aa.aaSector = questionnaire.questionSections[currentSection.qsIndex].qsTitle;
        if(props.question.qType === 'options'){
            aa.aaAnswer = props.question.answers ? props.question.answers[liveAnswer.activeAnswer].aText : '';
            aa.aaImpact = props.question.answers ? props.question.answers[liveAnswer.activeAnswer].aImpact : 0;
        }
        if(props.question.qType === 'multipleOptions'){
            aa.aaAnswer = "";
            aa.aaImpact = 0;
            for(let i = 0; i < liveAnswer.multipleValue.length; i ++){
                if(props.question && props.question.answers && 
                    props.question.answers[liveAnswer.multipleValue[i]]){
                    aa.aaAnswer += props.question.answers ? props.question.answers[liveAnswer.multipleValue[i]].aText + (i < liveAnswer.multipleValue.length-1 ? ',' : '') : '';
                }
                if(props.question && props.question.answers && liveAnswer.multipleValue
                    && liveAnswer.multipleValue[i] && props.question.answers[liveAnswer.multipleValue[i]].aImpact){
                    let impact = (props.question.answers ? props.question.answers[liveAnswer.multipleValue[i]].aImpact : 0);
                    aa.aaImpact += impact ? impact : 0;
                }
            }
        }
        if(props.question.qType === 'yesno'){
            aa.aaAnswer = liveAnswer.activeYesNo;
            aa.aaImpact = liveAnswer.activeYesNo === 'yes' ? props.question.qYesImpact : props.question.qNoImpact;
            if(liveAnswer.activeYesNo === 'yes' && props.question.qYesSuggestion){
                aa.aaSuggestion = props.question.qYesSuggestion;
            }
            if(liveAnswer.activeYesNo === 'no' && props.question.qNoSuggestion){
                aa.aaSuggestion = props.question.qNoSuggestion;
            }
        }
        if(props.question.qType === 'text'){
            aa.aaAnswer = liveAnswer.textValue;
            if(liveAnswer.textValue){
                aa.aaImpact = props.question.qYesImpact;
            }
            else{
                aa.aaImpact = 0;
            }
        }
        if(props.question.qType === 'range'){
            aa.aaAnswer = liveAnswer.rangeValue.toString();
            aa.aaImpact = liveAnswer.rangeValue;
        }
        if(props.question.qType === 'percentage'){
            aa.aaAnswer = liveAnswer.rangeValue.toString();
            aa.aaImpact = liveAnswer.rangeValue / 10;
            if(liveAnswer.rangeValue >= 50 && props.question.qYesSuggestion){
                aa.aaSuggestion = props.question.qYesSuggestion;
            }
            if(liveAnswer.rangeValue < 50 && props.question.qNoSuggestion){
                aa.aaSuggestion = props.question.qNoSuggestion;
            }
        }
        questionnaireService.answerQuestion(aa, assessment.qairId).then((result) => {
            if(result){
                let la = liveAnswer;
                la.saved = true;
                la.liveFiles = result as string;
                aa.aaFiles = result as string;
                answerQuestion(aa);
                addLiveAnswer(la);
            }
            else{
                
            }
        });
        return false;
    }

    function onAnswerSelected(a: number) {
        activeAnswer = a;
        const la = new LiveAnswer();
        la.qId = props.qId;
        la.activeAnswer = a;
        la.saved = false;
        addLiveAnswer(la);
    }

    function onMultipleAnswerSelected(a: number) {
        let found = -1;
        if(liveAnswer){
            if(!liveAnswer.multipleValue){
                liveAnswer.multipleValue = [];
            }
            for(let i: number = 0; i < liveAnswer.multipleValue.length; i ++){
                if(liveAnswer.multipleValue[i] === a){
                    found = i;
                }
            }
            if(found === -1){
                let mvs = liveAnswer.multipleValue;
                mvs.push(a);
                
                liveAnswer.multipleValue = mvs;
                
            }
            else{
                let mvs = liveAnswer.multipleValue;
                delete mvs[found];
                liveAnswer.multipleValue = mvs;
            }
            const la = new LiveAnswer();
            la.qId = props.qId;
            la.saved = false;
            la.multipleValue = liveAnswer.multipleValue;
            addLiveAnswer(la);
        }
        else{
            const la = new LiveAnswer();
            la.qId = props.qId;
            la.multipleValue = [a];
            la.saved = false;
            addLiveAnswer(la);
        }
        
    }

    function onYesNoSelected(a: string) {
        activeYesNo = a
        const la = new LiveAnswer();
        la.qId = props.qId;
        la.activeYesNo = a;
        la.saved = false;
        addLiveAnswer(la);
    }

    function onTextValueChanged(text: string){
        textValue = text
        const la = new LiveAnswer();
        la.qId = props.qId;
        la.textValue = text;
        la.saved = false;
        addLiveAnswer(la);
    }

    function onRangeChanged(v: number){
        rangeValue = v
        const la = new LiveAnswer();
        la.qId = props.qId;
        la.rangeValue = v;
        la.saved = false;
        addLiveAnswer(la);
    }

    function updateCommentValue(comment: string){
        commentValue = comment;
        if(!liveAnswer){
           liveAnswer = new LiveAnswer();
        }
        liveAnswer.commentValue = comment;
        addLiveAnswer(liveAnswer);
        console.log(commentValue);
    }

    function updateFilesValue(e: React.FormEvent<HTMLInputElement>){
        console.log(e.currentTarget);
        if(e.currentTarget.files){
            liveAnswer.filesValue = e.currentTarget.files;
        }
        else{
            liveAnswer.filesValue = new FileList();
        }
        addLiveAnswer(liveAnswer);
        console.log(filesValue);
    }

    function notApplicable(){
        const aa = new AssessmentAnswer();
        if(!liveAnswer){
            liveAnswer = new LiveAnswer();
        }
        liveAnswer.qId = props.qId;
        delete liveAnswer.activeYesNo;
        delete liveAnswer.activeAnswer;
        delete liveAnswer.multipleValue;
        delete liveAnswer.rangeValue;
        liveAnswer.saved = true;
        addLiveAnswer(liveAnswer);
        console.log('comment: ' + commentValue);
        aa.aaComment = liveAnswer.commentValue;
        aa.qId = props.question.qId;
        aa.aaFilesUpload = liveAnswer.filesValue;
        aa.userIdAnswered = parseInt(localStorage.getItem('userId') as string);
        aa.asId = assessment.asId;
        aa.aaSector = questionnaire.questionSections[currentSection.qsIndex].qsTitle;
        aa.aaAnswer = '||NOTAPPLICABLE||';
        aa.aaImpact = 0;
        answerQuestion(aa);
        questionnaireService.answerQuestion(aa, assessment.qairId);
        return false;
    }

    return (
        <Fragment>
            <Card color="light" style={{padding: "30px", fontSize: "19px", marginTop: '20px'}}>
                {props.question && props.question.qText } <br /><br />
                <Row>
                {props.question && props.question.qSdgs && props.question.qSdgs.split(',').map((sdg, si) => (
                    <Col md="2" ><img key={si} className="inline" src={'sdgs/E-WEB-Goal-' + sdg + '.png'} alt={sdg} style={{width: "100%"}} /></Col>
                ))}
                </Row>
                <br />
                {((liveAnswer && liveAnswer.saved) || savedAnswer) && <span className="text-success" style={{fontSize: '19px', fontWeight: 'bold'}}>Answered</span>}
            </Card>
            <Row style={{ borderBottomColor: '#444', borderBottomStyle: 'solid', 
                            borderBottomWidth: '17px', paddingBottom: "20px"}}>
                {
                    props.question && props.question.qType === 'options' 
                        && props.question.answers && (
                            <Col xs="12">
                            <Row>
                                {props.question.answers.map((a : Answer, index: number) => {
                                return (
                                    <Col style={{padding: '20px'}} xs="6" key={index}>
                                        <Button size="lg" onClick={() => { onAnswerSelected(index) }} className={ "answerButton btn-block " + (liveAnswer && liveAnswer.activeAnswer === index ? 'btn-custom-blue' : "btn-secondary")} >{a.aText}</Button>
                                    </Col>
                                    )
                                })
                                }
                            </Row>
                            </Col>)
                }
                {
                    props.question && props.question.qType === 'multipleOptions' 
                        && props.question.answers && (
                            <Col xs="12">
                            <Row>
                                {props.question.answers.map((a : Answer, index: number) => {
                                    return (
                                    <Col style={{padding: '20px'}} xs="6" key={index}>
                                        <Button size="lg" onClick={() => { onMultipleAnswerSelected(index) }} className={ " btn-block " + (liveAnswer && liveAnswer.multipleValue && liveAnswer.multipleValue.indexOf(index) > -1 ? 'btn-custom-blue' : "btn-secondary") } >{a.aText}</Button>
                                    </Col>
                                    )
                                })}
                            </Row>
                            </Col>
                        )
                }
                {
                    props.question && props.question.qType === 'yesno' && (
                        <Fragment>
                            <Col style={{padding: '20px'}} xs="6">
                                <Button size="lg" onClick={() => { onYesNoSelected('yes') }} className={ " btn-block " + (liveAnswer && liveAnswer.activeYesNo === 'yes' ? 'btn-custom-blue' : "btn-secondary")} >Yes</Button>
                            </Col>
                            <Col style={{padding: '20px'}} xs="6">
                                <Button size="lg" onClick={() => { onYesNoSelected('no') }} className={ " btn-block " + (liveAnswer && liveAnswer.activeYesNo === 'no' ? 'btn-custom-blue' : "btn-secondary")} >No</Button>
                            </Col>
                        </Fragment>
                    )
                }
                {
                    props.question && props.question.qType === 'text' && (
                        <Fragment>
                            <Col md="12">
                                <br />
                                <textarea className="form-control" onChange={(e) => { onTextValueChanged(e.target.value) }} value={(liveAnswer && liveAnswer.textValue !== '||NOTAPPLICABLE||' && liveAnswer.textValue) ? liveAnswer.textValue : '' }></textarea>
                            </Col>
                        </Fragment>
                    )
                }
                {
                    props.question && props.question.qType === 'range' && props.question.qRange && (
                        <Fragment>
                            {
                                <Col xs="12" className="text-center">
                                    { liveAnswer && liveAnswer.rangeValue ? liveAnswer.rangeValue : '-'}
                                </Col>
                            }
                                <Col xs="1" className="text-right">
                                    <span>{props.question.qRangeUi ? props.question.qRangeUi[0] : 0}</span>
                                </Col>
                                <Col xs="10">
                                    <Input type="range" id="exampleCustomRange" name="customRange" min={props.question.qRangeUi ? props.question.qRangeUi[0]: 0} 
                                        max={props.question.qRangeUi ? props.question.qRangeUi[1] : 10} onChange={(e) => {onRangeChanged(parseInt(e.target.value))}} value={liveAnswer && liveAnswer.rangeValue} />
                                </Col>
                                
                                <Col xs="1">
                                    <span>{props.question.qRangeUi ? props.question.qRangeUi[1] : 10}</span>
                                </Col>
                            
                        </Fragment>
                    )
                }
                {
                    props.question && props.question.qType === 'percentage' && (
                        <Fragment>
                            {
                                <Col xs="12" className="text-center">
                                    {(liveAnswer && liveAnswer.rangeValue ? liveAnswer.rangeValue : '-') + '%'}
                                </Col>
                            }
                            <Col xs="1" className="text-right">
                                <span>0%</span>
                            </Col>
                            <Col xs="10">
                                <Input type="range" id="exampleCustomRange" name="customRange" min={0} 
                                    max={100} step="any" onChange={(e) => {onRangeChanged(parseInt(e.target.value))}} value={liveAnswer && liveAnswer.rangeValue}/>
                            </Col>
                            <Col xs="1">
                                <span>100%</span>
                            </Col>
                        </Fragment>
                    )
                }
                <Col md="6">
                    <Label>Comments</Label><br />
                    <textarea className="form-control" rows={4} onChange={(e) => {updateCommentValue(e.currentTarget.value)}} value={liveAnswer && liveAnswer.commentValue ? liveAnswer.commentValue : ''}></textarea><br />
                </Col>
                <Col md="6">
                    <Label>Or upload a file</Label>
                    <Input type="file" multiple placeholder="Upload any documents here" onChange={updateFilesValue}></Input>
                    <br />
                    <Button type="button" color="danger" className="btn-block btn-lg" style={{opacity: 0.8}} onClick={() => {notApplicable(); return false;}}>Not Applicable</Button>
                </Col>
                {
                    savedAnswer && savedAnswer.aaFiles && savedAnswer.aaFiles > "" && JSON.parse(savedAnswer.aaFiles).map && (
                    <Col md="12">
                        <h3>Files</h3>
                        <table className="table table-striped">
                            {
                                JSON.parse(savedAnswer.aaFiles).map((f, k) => {
                                    return <tr key={k}>
                                        <td>{f.name}</td>
                                        <td><a download={f.name} target="_blank" href={ filesUrl + f.name } className="btn btn-custom-blue">Download</a></td>
                                    </tr>
                                })
                            }
                        </table>
                    </Col>)
                }
                {
                    (!savedAnswer || !savedAnswer.aaFiles) && liveAnswer && liveAnswer.liveFiles > "" && JSON.parse(liveAnswer.liveFiles).map && (
                        <Col md="12">
                            <h3>Files</h3>
                            <table className="table table-striped">
                                {
                                    JSON.parse(liveAnswer.liveFiles).map((f, k) => {
                                        return <tr key={k}>
                                            <td>{f.name}</td>
                                            <td><a download={f.name} target="_blank" href={ filesUrl + f.name } className="btn btn-custom-blue">Download</a></td>
                                        </tr>
                                    })
                                }
                            </table>
                        </Col>)
                }
                <Col md="12">
                    <Button type="button" style={{opacity: 0.8}} color={((liveAnswer && liveAnswer.saved) || savedAnswer) ? 'success' : 'light'} className="btn-block btn-lg" onClick={() => {saveAnswer(); return false;}}>Save Answer</Button>
                </Col>
            </Row>
        </Fragment>
    )

}

export default QuestionComponent;