import React, {useEffect} from 'react';
import { Container, Button } from 'reactstrap';
import { useStoreActions } from "../store";
import { useStoreState } from 'easy-peasy';
import { Assessment } from '../Models/Assessment';
import SmartTable from 'react-smart-data-table';
import "./Table.css";

function PortfolioComponent(){
    const assessment = useStoreState(state => state.assessment);
    const assessments = useStoreState(state => state.assessments);
    const questionnaires = useStoreState(state => state.questionnaires);
    const hidePortfolio = useStoreActions(actions => actions.hidePortfolio);
    
    class ResultType{
        qsTitle?: string;
        qsMaxImpact?: number;
        qsId?: number;
    }

    class Results{
        resultType?: ResultType;
        rScore?: number;
        assessment?: Assessment;
    }
    let totalQuestions = 0;

    function calculateResult(assessment: Assessment) : Results[]{
        const qair = questionnaires.filter(q => q.qairId === assessment.qairId)[0];
        const results: Array<Results> = [];
        if(qair){
            for(let i = 0; i < qair.questionSections.length; i ++){
                const rt = new ResultType();
                let mr = 0;
                const questionSection = qair.questionSections[i];
                rt.qsId = questionSection.qsId;
                rt.qsTitle = questionSection.qsTitle;
                for(let j = 0; j < questionSection.questionGroups.length; j ++){
                    const questionGroup = questionSection.questionGroups[j];
                    totalQuestions += questionGroup.questions.length;
                    for(let l = 0; l < questionGroup.questions.length; l++){
                        const q = questionGroup.questions[l];
                        mr += q.qMaxImpact;
                    }
                }
                rt.qsMaxImpact = mr;
                const res = new Results();
                res.resultType = rt;
                const answers = assessment.answers.filter(a => a.aaSector === rt.qsTitle);
                let r = 0;
                for(let k = 0; k < answers.length; k ++){
                    const a = answers[k];
                    if(a.aaImpact){
                        r += a.aaImpact;
                    }
                }
                res.rScore = r;
                results.push(res);
            }
            return results;
        }   
        else{
            return [];
        } 
    }
    const results = calculateResult(assessment);
    let overallScore: number = 0;
    for(let i = 0; i < results.length; i ++){
        const res = results[i];
        if(res && res.resultType){
            if(res.resultType.qsMaxImpact){
                overallScore += (res.rScore as number / res.resultType.qsMaxImpact) * 100;
            }
        }
    }   
    overallScore = overallScore / (results.length);
    const categoryResults: Results[][] = [];
    let categoryAssessments = assessments.filter(ass => ass.asset.assetCategory === assessment.asset.assetCategory)
    for(let i = 0; i < categoryAssessments.length; i ++){
        categoryResults.push(calculateResult(categoryAssessments[i]));
    }

    const allResults: Results[][] = [];
    for(let i = 0; i < assessments.length; i ++){
        allResults.push(calculateResult(assessments[i]));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let categoryDatatableData = [
        {
            'Asset': assessment.asset.assetTitle,
            'Environmental': results[0] && results[0].rScore !== undefined 
                                && results[0].resultType && results[0].resultType.qsMaxImpact ? 
                                parseInt(((results[0].rScore/results[0].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
            'Social': results[1] && results[1].rScore !== undefined 
                                && results[1].resultType && results[1].resultType.qsMaxImpact ? 
                                parseInt(((results[1].rScore/results[1].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
            'Governance': results[2] && results[2].rScore !== undefined 
                                && results[2].resultType && results[2].resultType.qsMaxImpact ? 
                                parseInt(((results[2].rScore/results[2].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
            'ESG' : results && results[0] && results[0].rScore !== undefined && results[0].resultType &&
                        results[0].resultType.qsMaxImpact && results[1] && results[1].rScore !== undefined && 
                        results[1].resultType && results[1].resultType.qsMaxImpact 
                        && results[2].rScore !== undefined && results[2] && results[2].resultType && 
                        results[2].resultType.qsMaxImpact ?
                        parseInt(((((results[0].rScore / results[0].resultType.qsMaxImpact) * 100 )+ 
                                ((results[1].rScore / results[1].resultType.qsMaxImpact) * 100) + 
                                ((results[2].rScore / results[2].resultType.qsMaxImpact) * 100)) / 3).toFixed(0)) : 0
        }
    ];

    for(let j = 0; j < categoryResults.length; j ++){
        let ress = categoryResults[j];
        if(categoryAssessments[j].asset.assetId !== assessment.asset.assetId){
            categoryDatatableData.push({
                'Asset': categoryAssessments[j].asset.assetTitle,
                'Environmental': ress[0] && ress[0].rScore !== undefined 
                                    && ress[0].resultType && ress[0].resultType.qsMaxImpact ? 
                                    parseInt(((ress[0].rScore/ress[0].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                'Social': ress[1] && ress[1].rScore !== undefined 
                                    && ress[1].resultType && ress[1].resultType.qsMaxImpact ? 
                                    parseInt(((ress[1].rScore/ress[1].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                'Governance': ress[2] && ress[2].rScore !== undefined 
                                    && ress[2].resultType && ress[2].resultType.qsMaxImpact ? 
                                    parseInt(((ress[2].rScore/ress[2].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                'ESG' : ress && ress[0] && ress[0].rScore !== undefined && ress[0].resultType &&
                            ress[0].resultType.qsMaxImpact && ress[1] && ress[1].rScore !== undefined && 
                            ress[1].resultType && ress[1].resultType.qsMaxImpact 
                            && ress[2].rScore !== undefined && ress[2] && ress[2].resultType && 
                            ress[2].resultType.qsMaxImpact ?
                            parseInt(((((ress[0].rScore / ress[0].resultType.qsMaxImpact) * 100 )+ 
                                    ((ress[1].rScore / ress[1].resultType.qsMaxImpact) * 100) + 
                                    ((ress[2].rScore / ress[2].resultType.qsMaxImpact) * 100)) / 3).toFixed(0)) : 0
            });
        }
    }

    let allDatatableData = [
        {
            'Asset': assessment.asset.assetTitle,
            'Environmental': results[0] && results[0].rScore !== undefined 
                                && results[0].resultType && results[0].resultType.qsMaxImpact ? 
                                parseInt(((results[0].rScore/results[0].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
            'Social': results[1] && results[1].rScore !== undefined 
                                && results[1].resultType && results[1].resultType.qsMaxImpact ? 
                                parseInt(((results[1].rScore/results[1].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
            'Governance': results[2] && results[2].rScore !== undefined 
                                && results[2].resultType && results[2].resultType.qsMaxImpact ? 
                                parseInt(((results[2].rScore/results[2].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
            'ESG' : results && results[0] && results[0].rScore !== undefined && results[0].resultType &&
                        results[0].resultType.qsMaxImpact && results[1] && results[1].rScore !== undefined && 
                        results[1].resultType && results[1].resultType.qsMaxImpact 
                        && results[2].rScore !== undefined && results[2] && results[2].resultType && 
                        results[2].resultType.qsMaxImpact ?
                        parseInt(((((results[0].rScore / results[0].resultType.qsMaxImpact) * 100 )+ 
                                ((results[1].rScore / results[1].resultType.qsMaxImpact) * 100) + 
                                ((results[2].rScore / results[2].resultType.qsMaxImpact) * 100)) / 3).toFixed(0)) : 0
        }
    ];

    for(let j = 0; j < allResults.length; j ++){
        let ress = allResults[j];
        if(assessments[j].asset.assetId !== assessment.asset.assetId){
            allDatatableData.push({
                'Asset': assessments[j].asset.assetTitle,
                'Environmental': ress[0] && ress[0].rScore !== undefined 
                                    && ress[0].resultType && ress[0].resultType.qsMaxImpact ? 
                                    parseInt(((ress[0].rScore/ress[0].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                'Social': ress[1] && ress[1].rScore !== undefined 
                                    && ress[1].resultType && ress[1].resultType.qsMaxImpact ? 
                                    parseInt(((ress[1].rScore/ress[1].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                'Governance': ress[2] && ress[2].rScore !== undefined 
                                    && ress[2].resultType && ress[2].resultType.qsMaxImpact ? 
                                    parseInt(((ress[2].rScore/ress[2].resultType.qsMaxImpact) * 100).toFixed(0)) : 0,
                'ESG' : ress && ress[0] && ress[0].rScore !== undefined && ress[0].resultType &&
                            ress[0].resultType.qsMaxImpact && ress[1] && ress[1].rScore !== undefined && 
                            ress[1].resultType && ress[1].resultType.qsMaxImpact 
                            && ress[2].rScore !== undefined && ress[2] && ress[2].resultType && 
                            ress[2].resultType.qsMaxImpact ?
                            parseInt(((((ress[0].rScore / ress[0].resultType.qsMaxImpact) * 100 )+ 
                                    ((ress[1].rScore / ress[1].resultType.qsMaxImpact) * 100) + 
                                    ((ress[2].rScore / ress[2].resultType.qsMaxImpact) * 100)) / 3).toFixed(0)) : 0
            });
        }
    }
    
    return (
        <Container>
            <h2 className="text-center">Portfolio</h2>
            <Button className="btn-custom-blue btn-block" color="light" onClick={() => {hidePortfolio()}}>Proceed with Assessment</Button>
            <hr />
            <h5>{assessment.asset.assetCategory}</h5>
            <SmartTable data={categoryDatatableData}
                name='categoryData'
                className='selectable table table-striped dataTable'
                sortable></SmartTable>
            <hr />
            <h5>All</h5>
            <SmartTable data={allDatatableData}
                name='allData'
                className='selectable table table-striped dataTable'
                sortable></SmartTable>
        </Container>
    )
}

export default PortfolioComponent;